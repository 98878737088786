var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"udfe"},[_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"treatment_monthly_amount",attrs:{"label":"Kwota","placeholder":"Kwota brutto","show-asterisk":"","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 31) { return 'Maksymalna długość kwoty to 31 znaków'; }
                    if (!this$1.$store.state.REGEX_IS_MONEY.test(v))
                        { return 'Kwota musi zawierać liczbę'; }
                    return true;
                }
            ]},on:{"input":_vm.emitUpdate,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('submit')}},model:{value:(_vm.survey.borrowed_funds_allocation.treatment_monthly_amount),callback:function ($$v) {_vm.$set(_vm.survey.borrowed_funds_allocation, "treatment_monthly_amount", $$v)},expression:"survey.borrowed_funds_allocation.treatment_monthly_amount"}})],1),_c('WkTextarea',{ref:"treated_diseases",attrs:{"label":"Wymień choroby","placeholder":"Wymień","rows":8,"counter":"1023","show-asterisk":"","rules":[
            function (v) {
                if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                else if (v.length > 1023)
                    { return 'Maksymalna długość opisu chorób to 1023 znaki'; }
                return true;
            }
        ]},on:{"input":_vm.emitUpdate},model:{value:(_vm.survey.borrowed_funds_allocation.treated_diseases),callback:function ($$v) {_vm.$set(_vm.survey.borrowed_funds_allocation, "treated_diseases", $$v)},expression:"survey.borrowed_funds_allocation.treated_diseases"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }