var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WkInput',{ref:"wk-input",attrs:{"id":_vm.local_id,"hint":_vm.hint,"label":_vm.label,"rules":_vm.rules,"show-asterisk":_vm.showAsterisk,"value":_vm.v,"error":_vm.error_state},on:{"update:error":function($event){_vm.error_state=$event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.hasLabelSlot)?_vm._t("label"):_vm._e()]},proxy:true},{key:"hint",fn:function(){return [(_vm.hasHintSlot)?_vm._t("hint"):_vm._e()]},proxy:true}],null,true)},[_c('div',{staticClass:"pp-stepper-input",class:{
            'pp-stepper-input--focused': _vm.focused_state,
            'pp-stepper-input--disabled': _vm.disabled
        },on:{"click":function($event){return _vm.$refs.input.focus()},"focus":function($event){return _vm.$refs['input'].focus()}}},[_c('button',{staticClass:"pp-stepper-input__button",class:{
                'pp-stepper-input__button--disabled': _vm.disabled
            },attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return (function (e) {
                    if (_vm.v > _vm.min) {
                        _vm.setValue(_vm.v - _vm.step);
                    }
                    _vm.$emit('click:minus', e);
                }).apply(null, arguments)}}},[_c('PpIcon',{attrs:{"name":"minus"}})],1),_c('div',{staticClass:"pp-stepper-input__inner-wrapper"},[_c('input',{ref:"input",staticClass:"pp-stepper-input__inner-input",attrs:{"type":"number","name":_vm.name,"id":_vm.local_id,"min":_vm.min,"max":_vm.max,"disabled":_vm.disabled},domProps:{"value":_vm.v},on:{"focus":function($event){_vm.focused_state = true},"blur":function($event){_vm.focused_state = false},"input":_vm.onInput}}),(!_vm.focused_state && _vm.v === '')?_c('div',{staticClass:"pp-stepper-input__placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e()]),_c('button',{staticClass:"pp-stepper-input__button",class:{
                'pp-stepper-input__button--disabled': _vm.disabled
            },attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return (function (e) {
                    if (_vm.v < _vm.max) {
                        _vm.setValue(_vm.v + _vm.step);
                    }
                    _vm.$emit('click:plus', e);
                }).apply(null, arguments)}}},[_c('PpIcon',{attrs:{"name":"plus"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }