//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "@/components/mixins";
import PpStepperInput from "../PpStepperInput.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpStepperInput
    },

    data() {
        return {
            daughters_amount: "",
            sons_amount: ""
        };
    },

    methods: {
        handleChild(type) {
            // zawsze robimy refresh tej tablicy z tym czy dzieci są na utrzymaniu czy nie
            this.survey.childs[type] = new Array(this[`${type}_amount`]).fill({
                legal_age: false,
                is_depend: ""
            });
            // ustawienie walidacji następnego kroku na false
            this.$store.commit("formData/setSurveyValidation", {
                key: "childs_dependencies",
                value: "Ilość dzieci uległa zmianie. Proszę ponownie uzupełnić dane w tym kroku"
            });

            if (type === "sons") {
                if (this.$refs.no_of_daughters) {
                    this.$refs.no_of_daughters.validate();
                }
            } else {
                if (this.$refs.no_of_sons) {
                    this.$refs.no_of_sons.validate();
                }
            }
            this.emitUpdate();
        },

        validate() {
            let result = true;

            const validations = [
                this.$refs.no_of_daughters.validate(),
                this.$refs.no_of_sons.validate()
            ];

            if (validations.includes(false)) {
                result = "Proszę poprawić błędy w formularzu";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "childs_count",
                value: result
            });

            return result;
        }
    },

    mounted() {
        if (this.daughters_amount && this.sons_amount) {
            this.handleChild("sons");
            this.handleChild("daughters");
        } else {
            this.daughters_amount = this.survey.childs.daughters.length;
            this.sons_amount = this.survey.childs.sons.length;
        }
    }
};
