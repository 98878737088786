//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "@/components/mixins";
import PpComboDateInput from "@/components//PpComboDateInput.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpComboDateInput
    },

    methods: {
        validate() {
            let result = true;

            const validations = [this.$refs.property_separation_start_date.validate()];

            if (validations.includes(false)) {
                result = "Proszę podać poprawną datę uzyskania rozdzielności majątkowej";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "property_separation_start_date",
                value: result
            });

            return result;
        }
    }
};
