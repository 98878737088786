var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"udfe"},[_c('div',{staticClass:"survey__input-wrapper"},[_c('WkTextField',{ref:"rent_price",staticClass:"survey__input-cell mr-6",attrs:{"label":"Kwota wynajmu","placeholder":"Kwota brutto","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 31) { return 'Maksymalna długość kwoty to 31 znaków'; }
                    if (!this$1.$store.state.REGEX_IS_MONEY.test(v))
                        { return 'Kwota musi zawierać liczbę'; }
                    return true;
                }
            ],"show-asterisk":""},on:{"input":_vm.emitUpdate,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('submit')}},model:{value:(_vm.survey.flat_rent.rent_price),callback:function ($$v) {_vm.$set(_vm.survey.flat_rent, "rent_price", $$v)},expression:"survey.flat_rent.rent_price"}}),_c('WkTextField',{ref:"flat_size",staticClass:"survey__input-cell",attrs:{"label":"Metraż mieszkania","placeholder":"m2","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    else if (v.length > 63)
                        { return 'Maksymalna długość metrażu mieszkania to 63 znaki'; }
                    return true;
                }
            ],"show-asterisk":""},on:{"input":_vm.emitUpdate,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('submit')}},model:{value:(_vm.survey.flat_rent.flat_size),callback:function ($$v) {_vm.$set(_vm.survey.flat_rent, "flat_size", $$v)},expression:"survey.flat_rent.flat_size"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }