//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import countries from "@/static-store/countries.json";

export default {
    props: {
        value: {
            type: Object
        },
        allowEmpty: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            address: {
                country: "PL",
                city: "",
                street: "",
                house_no: "",
                apartment_no: "",
                zip_code: ""
            },

            country_select_search: ""
        };
    },

    computed: {
        countrySelectItems() {
            const c = countries
                .map(country => ({
                    text: country.name.pl,
                    value: country.code
                }))
                .sort((a, b) => {
                    if (a.text > b.text) return 1;
                    else if (b.text > a.text) return -1;
                    else return 0;
                });
            if (this.country_select_search != "") {
                return c.filter(country =>
                    country.text.toLowerCase().includes(this.country_select_search.toLowerCase())
                );
            }
            return c;
        }
    },

    watch: {
        value() {
            this.handleValue();
        }
    },

    methods: {
        handleValue() {
            if (this.value) {
                this.address = JSON.parse(JSON.stringify(this.value));
            }
        },

        validate() {
            if (
                this.allowEmpty &&
                this.address.country === "" &&
                this.address.city === "" &&
                this.address.street === "" &&
                this.address.house_no === "" &&
                this.address.apartment_no === "" &&
                this.address.zip_code === ""
            ) {
                this.$refs.street.resetValidation();
                this.$refs.house_no.resetValidation();
                this.$refs.apartment_no.resetValidation();
                this.$refs.zip_code.resetValidation();
                this.$refs.city.resetValidation();
                this.$refs.country.resetValidation();
                return true;
            }

            const a = [
                this.$refs.street.validate(),
                this.$refs.house_no.validate(),
                this.$refs.apartment_no.validate(),
                this.$refs.zip_code.validate(),
                this.$refs.city.validate(),
                this.$refs.country.validate()
            ];
            if (a.indexOf(false) !== -1) {
                return false;
            }
            return true;
        },

        emitUpdate() {
            this.$emit("input", this.address);
            this.$emit("change", this.address);
        }
    },

    mounted() {
        this.handleValue();
    }
};
