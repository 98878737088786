var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"udfe"},[_c('PpComboDateInput',{ref:"start_date",attrs:{"rules":[
            function (v) {
                var date = new Date(v);
                if (date.getTime() > Date.now()) {
                    return 'Proszę podać datę z przeszłości';
                }
                return true;
            }
        ]},on:{"input":_vm.emitUpdate,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('submit')}},model:{value:(_vm.survey.own_company.start_date),callback:function ($$v) {_vm.$set(_vm.survey.own_company, "start_date", $$v)},expression:"survey.own_company.start_date"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }