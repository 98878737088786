//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        label: {
            type: String,
            default: ""
        },
        hint: {
            type: String,
            default: ""
        },
        rules: {
            type: Array,
            default() {
                return [];
            }
        },
        showAsterisk: {
            type: Boolean,
            deafult: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        id: {
            type: String,
            default: ""
        },
        value: {
            type: [String, Number],
            default: 0
        },
        min: {
            type: Number
        },
        max: {
            type: Number
        },
        disabled: {
            type: Boolean,
            default: false
        },
        step: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            v: "",
            local_id: "",
            error_state: false,
            focused_state: false
        };
    },

    watch: {
        id() {
            this.handleId();
        },
        value() {
            this.handleValue();
        }
    },

    computed: {
        hasLabelSlot() {
            return !!this.$slots.label;
        },
        hasHintSlot() {
            return !!this.$slots.hint;
        }
    },

    methods: {
        handleId() {
            if (this.id) {
                this.local_id = this.id;
            } else {
                this.local_id = "input_" + (Date.now() + Math.random() * 100000).toString(16);
            }
        },

        onInput(e) {
            this.setValue(Math.floor(+e.target.value));
        },

        validate() {
            return this.$refs["wk-input"].validate();
        },

        resetValidation() {
            return this.$refs["wk-input"].resetValidation();
        },

        setValue(value) {
            this.$emit("input", value);
            this.$nextTick(this.validate);
        },

        handleValue() {
            if (this.value != undefined) {
                this.v = +this.value;
            }
        }
    },

    created() {
        this.handleId();
        this.handleValue();
    }
};
