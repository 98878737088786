//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpRadioButton from "@/components/PpRadioButton.vue";
import { updateSurveyData } from "../mixins";

export default {
    mixins: [updateSurveyData],

    components: {
        PpRadioButton
    }
};
