//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import reverseData from "../../helpers/reverse-data";
import { updateSurveyData } from "../mixins";
import PpDataField from "../PpDataField.vue";
import SurveyTransactionFormElement from "./SurveyTransactionFormElement.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpDataField,
        SurveyTransactionFormElement
    },

    data() {
        return {
            is_display_modal: false
        };
    },

    methods: {
        openModal() {
            this.is_display_modal = true;
        },
        closeModal() {
            this.is_display_modal = false;
        },

        addTransaction() {
            if (this.$refs.tfe.validate() === true) {
                this.survey.l12m_movable_property_transactions.transactions.push(
                    this.$refs.tfe.saveData()
                );
                this.emitUpdate();
                this.closeModal();
            }
        },

        deleteTransaction(index) {
            this.survey.l12m_movable_property_transactions.transactions.splice(index, 1);
            this.emitUpdate();
        },

        getTransactionInfo(transaction_details) {
            return `${
                transaction_details.transaction_type === "sell" ? "Sprzedaż" : "Darowizna"
            } • ${transaction_details.amount} • Data: ${reverseData(
                transaction_details.transaction_date
            )}`;
        },

        validate() {
            let result = true;

            if (this.survey.l12m_movable_property_transactions.transactions.length === 0) {
                result = "Dodaj co najmniej jedną transakcję";
            }
            if (this.survey.l12m_movable_property_transactions.transactions.length > 127) {
                result = "Możesz dodać maksymalnie 127 transakcji";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "l12m_movable_property_transactions",
                value: result
            });

            return result;
        }
    }
};
