var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-10"},[_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"cash_amount",attrs:{"type":"number","label":"Wartość salda","rules":[
                function (v) {
                    if (v > _vm.$store.state.MAX_SAFE_INTEGER)
                        { return 'Maksymalna kwota została przekroczona'; }
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v < 0) { return 'Wartość salda nie może być niższa od 0'; }
                    return true;
                }
            ],"show-asterisk":""},model:{value:(_vm.local_data.cash_amount),callback:function ($$v) {_vm.$set(_vm.local_data, "cash_amount", _vm._n($$v))},expression:"local_data.cash_amount"}})],1),_c('div',[_c('WkSelect',{style:(( _obj = { position: 'relative' }, _obj['z-index'] = 20000, _obj )),attrs:{"label":"Waluta","placeholder":"Wybierz walutę","show-asterisk":"","items":_vm.currencySelectItems,"empty-list-msg":"Waluty nie ma na liście"},on:{"change":function($event){_vm.currency_select_search = ''}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"survey__searchbar-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currency_select_search),expression:"currency_select_search"}],staticClass:"survey__searchbar",attrs:{"type":"text","placeholder":"Szukaj","tabindex":"-1"},domProps:{"value":(_vm.currency_select_search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currency_select_search=$event.target.value}}})])]},proxy:true}]),model:{value:(_vm.local_data.currency),callback:function ($$v) {_vm.$set(_vm.local_data, "currency", $$v)},expression:"local_data.currency"}})],1),_c('h5',{staticClass:"text-h6 mt-4 mb-2"},[_vm._v("Adres przechowywania")]),_c('div',{staticClass:"pa-2",staticStyle:{"background-color":"#f6f9fc"}},[_c('GeneralAddressFormElement',{ref:"address_form",model:{value:(_vm.local_data.address),callback:function ($$v) {_vm.$set(_vm.local_data, "address", $$v)},expression:"local_data.address"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }