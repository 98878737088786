//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "@/components/mixins";
import PpComboDateInput from "../PpComboDateInput.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpComboDateInput
    },

    props: {
        employmentType: {
            type: String
        }
    },

    methods: {
        validate() {
            let result = true;

            const validations = [
                this.$refs.company_name.validate(),
                this.$refs.company_address.validate(),
                this.$refs.company_nip.validate(),
                this.$refs.position.validate(),
                this.$refs.salary.validate(),
                this.$refs.start_date.validate()
            ];

            if (validations.includes(false)) {
                result = "Proszę poprawić błędy w formularzu";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key:
                    this.employmentType === "hired"
                        ? "hired_employment_place"
                        : "odd_job_employment_place",
                value: result
            });

            return result;
        }
    }
};
