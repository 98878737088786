var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"bank_name",attrs:{"type":"text","label":"Nazwa banku","show-asterisk":"","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    else if (v.length > 63) { return 'Maksymalna długość nazwy banku to 63 znaki'; }
                    return true;
                }
            ]},model:{value:(_vm.local_data.bank_name),callback:function ($$v) {_vm.$set(_vm.local_data, "bank_name", $$v)},expression:"local_data.bank_name"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"account_number",attrs:{"type":"text","label":"Numer konta","show-asterisk":"","rules":[
                function (v) {
                    var n = v.replaceAll(' ', '');
                    if (!n || n.length == 0) { return 'To pole jest wymagane'; }
                    if (n.length > 34) { return 'Maksymalna długość numeru konta to 34 znaki'; }
                    if (!/^[A-Za-z0-9]{1,34}$/.test(n)) { return 'Użyj tylko cyfr lub liter'; }
                    return true;
                }
            ]},model:{value:(_vm.local_data.account_number),callback:function ($$v) {_vm.$set(_vm.local_data, "account_number", $$v)},expression:"local_data.account_number"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }