var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"udfe"},[_c('WkTextField',{ref:"debt_cancellation_amount",attrs:{"label":"Wartość w %","placeholder":"Ile %","show-asterisk":"","type":"number","rules":[
            function (v) {
                if (!v) { return 'Pole jest wymagane'; }
                if (v < 0) { return 'Minimalna wartość w % nie może być niższa od 0'; }
                if (v > 100) { return 'Maksymalna wartość w % nie może być wyższa niż 100'; }
                if (!Number.isInteger(v)) { return 'Proszę podać liczbą całkowitą'; }
                return true;
            }
        ]},on:{"input":_vm.emitUpdate,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('submit')}},model:{value:(_vm.survey.l10y_bankruptcy_application.debt_cancellation_amount),callback:function ($$v) {_vm.$set(_vm.survey.l10y_bankruptcy_application, "debt_cancellation_amount", _vm._n($$v))},expression:"survey.l10y_bankruptcy_application.debt_cancellation_amount"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }