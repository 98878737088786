var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"udfe"},[_c('div',{staticClass:"survey__input-wrapper",class:{ 'survey__input-wrapper--col': _vm.singleColumn }},[_c('WkTextField',{ref:"property_value",staticClass:"survey__input-cell mr-6",class:{ 'survey__input-cell--col': _vm.singleColumn },attrs:{"label":"Wartość","placeholder":"Wartość brutto","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 31) { return 'Maksymalna długość kwoty to 31 znaków'; }
                    if (!this$1.$store.state.REGEX_IS_MONEY.test(v))
                        { return 'Proszę podać prawidłową kwotę'; }
                    return true;
                }
            ],"show-asterisk":""},on:{"input":_vm.emitUpdate,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('submit')}},model:{value:(_vm.survey.real_estate.property_value),callback:function ($$v) {_vm.$set(_vm.survey.real_estate, "property_value", $$v)},expression:"survey.real_estate.property_value"}}),(_vm.survey.real_estate.ownership === 'shared_ownership')?_c('WkTextField',{ref:"ownership_percentage",staticClass:"survey__input-cell",class:{ 'survey__input-cell--col': _vm.singleColumn },attrs:{"label":"Własność w procentach","suffix":"%","type":"number","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (!v || v < 1) { return 'Minimalna wartość powinna wynosić 1'; }
                    if (v > 100) { return 'Maksymalna wartość nie może przekraczać 100'; }
                    return true;
                }
            ],"show-asterisk":""},on:{"input":_vm.emitUpdate,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('submit')}},model:{value:(_vm.survey.real_estate.ownership_percentage),callback:function ($$v) {_vm.$set(_vm.survey.real_estate, "ownership_percentage", _vm._n($$v))},expression:"survey.real_estate.ownership_percentage"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }