//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "../mixins";

export default {
    mixins: [updateSurveyData],

    props: {
        label: {
            type: String
        },
        value: {
            type: Object
        }
    },

    data() {
        return {
            local_data: {
                bank_name: "",
                account_number: ""
            },
            currency_select_search: ""
        };
    },

    watch: {
        value() {
            this.handleValue();
        }
    },

    methods: {
        saveData() {
            return this.local_data;
        },
        handleValue() {
            if (this.value) {
                this.local_data = { ...this.value };
            }
        },
        validate() {
            const validations = [
                this.$refs.bank_name.validate(),
                this.$refs.account_number.validate()
            ];

            if (validations.includes(false)) {
                return false;
            }

            return true;
        }
    },

    mounted() {
        this.handleValue();
    }
};
