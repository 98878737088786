//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "@/components/mixins";

export default {
    mixins: [updateSurveyData],

    props: {
        singleColumn: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            select_items: [
                {
                    text: "Tak, jest na moim utrzymaniu",
                    value: true
                },
                {
                    text: "Nie, jest samodzielny(-a)",
                    value: false
                }
            ],
            rules: [
                v => {
                    if (v === "") return "To pole jest wymagane";
                    return true;
                }
            ]
        };
    },

    methods: {
        validate() {
            const a = [];
            let result = true;

            for (let i = 0; i < this.survey.childs.daughters.length; i++) {
                a.push(this.$refs["daughter_" + i][0].validate());
            }
            for (let i = 0; i < this.survey.childs.sons.length; i++) {
                a.push(this.$refs["son_" + i][0].validate());
            }

            if (a.indexOf(false) !== -1) {
                result = "Proszę odpowiedzieć na pytania odnośnie każdego dziecka";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "childs_dependencies",
                value: result
            });

            return result;
        }
    }
};
