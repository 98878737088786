//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "@/components/mixins";

export default {
    mixins: [updateSurveyData],

    methods: {
        validate() {
            let result = true;

            const validations = [this.$refs.real_estate_co_owners.validate()];

            if (validations.includes(false)) {
                result = "Proszę poprawić błędy w formularzu";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "real_estate_co_owners",
                value: result
            });

            return result;
        }
    }
};
