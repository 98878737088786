//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "@/components/mixins";
import revData from "@/helpers/reverse-data.js";
import PpRadioButton from "@/components/PpRadioButton.vue";
import PpEditorData from "../PpEditorData.vue";
import SurveyLivingAlone from "./SurveyLivingAlone.vue";
import SurveyOtherResidents from "./SurveyOtherResidents.vue";
import SurveyChildsHasChilds from "./SurveyChildsHasChilds.vue";
import SurveyChildsCount from "./SurveyChildsCount.vue";
import SurveyChildsDependencies from "./SurveyChildsDependencies.vue";
import SurveyIsMarried from "./SurveyIsMarried.vue";
import SurveyPropertySeparationStatus from "./SurveyPropertySeparationStatus.vue";
import SurveyPropertySeparationStartDate from "./SurveyPropertySeparationStartDate.vue";
import SurveyIsDivorced from "./SurveyIsDivorced.vue";
import SurveyOwnCompanyHasOwnCompany from "./SurveyOwnCompanyHasOwnCompany.vue";
import SurveyOwnCompanyStartDate from "./SurveyOwnCompanyStartDate.vue";
import SurveyOwnCompanyIsSuspended from "./SurveyOwnCompanyIsSuspended.vue";
import SurveyOwnCompanyHasEverRunning from "./SurveyOwnCompanyHasEverRunning.vue";
import SurveyOwnCompanySuspensionDate from "./SurveyOwnCompanySuspensionDate.vue";
import SurveyOwnCompanyCloseDate from "./SurveyOwnCompanyCloseDate.vue";
import SurveyHiredIsHired from "./SurveyHiredIsHired.vue";
import SurveyEmploymentPlace from "./SurveyEmploymentPlace.vue";
import SurveyOddJob from "./SurveyOddJob.vue";
import SurveyLast6MonthsIncomeHasIncome from "./SurveyLast6MonthsIncomeHasIncome.vue";
import SurveyAddIncomeSources from "./SurveyAddIncomeSources.vue";
import SurveyUnemployedStatusHasStatus from "./SurveyUnemployedStatusHasStatus.vue";
import SurveyUnemployedStatusStatusGrantDate from "./SurveyUnemployedStatusStatusGrantDate.vue";
import SurveyPandemicImpactAffectedEmployment from "./SurveyPandemicImpactAffectedEmployment.vue";
import SurveyPandemicImpactEmploymentAffectionDescription from "./SurveyPandemicImpactEmploymentAffectionDescription.vue";
import SurveyRetirementIsRetired from "./SurveyRetirementIsRetired.vue";
import SurveyRetirementDetails from "./SurveyRetirementDetails.vue";
import SurveyHealthPensionIsPensioner from "./SurveyHealthPensionIsPensioner.vue";
import SurveyHealthPensionDetails from "./SurveyHealthPensionDetails.vue";
import SurveyDisabledPersonIsDisabled from "./SurveyDisabledPersonIsDisabled.vue";
import SurveyDisabledPersonDetails from "./SurveyDisabledPersonDetails.vue";
import SurveyPandemicImpactAffectedFinances from "./SurveyPandemicImpactAffectedFinances.vue";
import SurveyPandemicImpactFinancesAffectionDetails from "./SurveyPandemicImpactFinancesAffectionDetails.vue";
import SurveyLast12MonthsMovablePropertyTransactionsTransactionsMade from "./SurveyLast12MonthsMovablePropertyTransactionsTransactionsMade.vue";
import SurveyAddTransactionsOnMovables from "./SurveyAddTransactionsOnMovables.vue";
import SurveyIncurringDebtsMomentCreditworthiness from "./SurveyIncurringDebtsMomentCreditworthiness.vue";
import SurveyIncurringDebtsMomentJob from "./SurveyIncurringDebtsMomentJob.vue";
import SurveyIncurringDebtsMomentMarriage from "./SurveyIncurringDebtsMomentMarriage.vue";
import SurveyIncurringDebtsMomentSpouseJob from "./SurveyIncurringDebtsMomentSpouseJob.vue";
import SurveyBorrowedFundsAllocationPreviousLoanRepayment from "./SurveyBorrowedFundsAllocationPreviousLoanRepayment.vue";
import SurveyBorrowedFundsAllocationPreviousLoanRepaymentAmount from "./SurveyBorrowedFundsAllocationPreviousLoanRepaymentAmount.vue";
import SurveyBorrowedFundsAllocationBillsAndLiving from "./SurveyBorrowedFundsAllocationBillsAndLiving.vue";
import SurveyBorrowedFundsAllocationBillsAndLivingAmount from "./SurveyBorrowedFundsAllocationBillsAndLivingAmount.vue";
import SurveyBorrowedFundsAllocationTreatment from "./SurveyBorrowedFundsAllocationTreatment.vue";
import SurveyBorrowedFundsAllocationTreatmentDetails from "./SurveyBorrowedFundsAllocationTreatmentDetails.vue";
import SurveyShareholdingDebtResponsibility from "./SurveyShareholdingDebtResponsibility.vue";
import SurveyShareholdingDebtResponsibilityEndDate from "./SurveyShareholdingDebtResponsibilityEndDate.vue";
import SurveyLast10YearsBankruptcyApplicationApplicationSubmitted from "./SurveyLast10YearsBankruptcyApplicationApplicationSubmitted.vue";
import SurveyLast10YearsBankruptcyApplicationCourtDecisionDate from "./SurveyLast10YearsBankruptcyApplicationCourtDecisionDate.vue";
import SurveyLast10YearsBankruptcyApplicationDebtCancellation from "./SurveyLast10YearsBankruptcyApplicationDebtCancellation.vue";
import SurveyLast10YearsBankruptcyApplicationDebtCancellationAmount from "./SurveyLast10YearsBankruptcyApplicationDebtCancellationAmount.vue";
import SurveyLast10YearsCreditorsDetriment from "./SurveyLast10YearsCreditorsDetriment.vue";
import SurveyLast10YearsCreditorsDetrimentDescription from "./SurveyLast10YearsCreditorsDetrimentDescription.vue";
import SurveyRealEstateOwnership from "./SurveyRealEstateOwnership.vue";
import SurveyRealEstateCoOwners from "./SurveyRealEstateCoOwners.vue";
import SurveyRealEstateDetails from "./SurveyRealEstateDetails.vue";
import SurveyFlatRentIsRenting from "./SurveyFlatRentIsRenting.vue";
import SurveyFlatRentDetails from "./SurveyFlatRentDetails.vue";
import SurveyHasBankAccounts from "./SurveyHasBankAccounts.vue";
import SurveyAddBankAccounts from "./SurveyAddBankAccounts.vue";
import SurveyHasBankDeposits from "./SurveyHasBankDeposits.vue";
import SurveyAddBankDeposits from "./SurveyAddBankDeposits.vue";
import SurveyHasCash from "./SurveyHasCash.vue";
import SurveyAddCash from "./SurveyAddCash.vue";
import { removeFromLocalStorage } from "../../helpers/local-storage";

export default {
    mixins: [updateSurveyData],

    components: {
        PpRadioButton,
        PpEditorData,
        SurveyLivingAlone,
        SurveyOtherResidents,
        SurveyChildsHasChilds,
        SurveyChildsCount,
        SurveyChildsDependencies,
        SurveyIsMarried,
        SurveyPropertySeparationStatus,
        SurveyPropertySeparationStartDate,
        SurveyIsDivorced,
        SurveyOwnCompanyHasOwnCompany,
        SurveyOwnCompanyStartDate,
        SurveyOwnCompanyIsSuspended,
        SurveyOwnCompanyHasEverRunning,
        SurveyOwnCompanySuspensionDate,
        SurveyOwnCompanyCloseDate,
        SurveyHiredIsHired,
        SurveyEmploymentPlace,
        SurveyOddJob,
        SurveyLast6MonthsIncomeHasIncome,
        SurveyAddIncomeSources,
        SurveyUnemployedStatusHasStatus,
        SurveyUnemployedStatusStatusGrantDate,
        SurveyPandemicImpactAffectedEmployment,
        SurveyPandemicImpactEmploymentAffectionDescription,
        SurveyRetirementIsRetired,
        SurveyRetirementDetails,
        SurveyHealthPensionIsPensioner,
        SurveyHealthPensionDetails,
        SurveyDisabledPersonIsDisabled,
        SurveyDisabledPersonDetails,
        SurveyPandemicImpactAffectedFinances,
        SurveyPandemicImpactFinancesAffectionDetails,
        SurveyLast12MonthsMovablePropertyTransactionsTransactionsMade,
        SurveyAddTransactionsOnMovables,
        SurveyIncurringDebtsMomentCreditworthiness,
        SurveyIncurringDebtsMomentJob,
        SurveyIncurringDebtsMomentMarriage,
        SurveyIncurringDebtsMomentSpouseJob,
        SurveyBorrowedFundsAllocationPreviousLoanRepayment,
        SurveyBorrowedFundsAllocationPreviousLoanRepaymentAmount,
        SurveyBorrowedFundsAllocationBillsAndLiving,
        SurveyBorrowedFundsAllocationBillsAndLivingAmount,
        SurveyBorrowedFundsAllocationTreatment,
        SurveyBorrowedFundsAllocationTreatmentDetails,
        SurveyShareholdingDebtResponsibility,
        SurveyShareholdingDebtResponsibilityEndDate,
        SurveyLast10YearsBankruptcyApplicationApplicationSubmitted,
        SurveyLast10YearsBankruptcyApplicationCourtDecisionDate,
        SurveyLast10YearsBankruptcyApplicationDebtCancellation,
        SurveyLast10YearsBankruptcyApplicationDebtCancellationAmount,
        SurveyLast10YearsCreditorsDetriment,
        SurveyLast10YearsCreditorsDetrimentDescription,
        SurveyRealEstateOwnership,
        SurveyRealEstateCoOwners,
        SurveyRealEstateDetails,
        SurveyFlatRentIsRenting,
        SurveyFlatRentDetails,
        SurveyHasBankAccounts,
        SurveyAddBankAccounts,
        SurveyHasBankDeposits,
        SurveyAddBankDeposits,
        SurveyHasCash,
        SurveyAddCash
    },

    props: {
        value: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },

    data() {
        return {
            revData,
            isDisplayConfirmModal: false,
            loading: false
        };
    },

    computed: {
        app() {
            return this.$store.getters["application/getApplication"];
        },
        surveyValidations() {
            return this.$store.state.formData.survey_validations;
        }
    },

    methods: {
        openConfirmModal() {
            this.isDisplayConfirmModal = true;
        },

        displayErrorMessage(item) {
            if (typeof this.surveyValidations[item] === "string") {
                return this.surveyValidations[item];
            } else {
                return "Sprawdź poprawność odpowiedzi";
            }
        },

        validateSummary() {
            const survey_copy = JSON.parse(JSON.stringify(this.survey));

            let result = true;

            if (
                survey_copy.living_alone === false &&
                this.surveyValidations.other_residents !== true
            ) {
                result = false;
            }

            if (
                survey_copy.childs.has_childs === true &&
                this.surveyValidations.childs_count !== true
            ) {
                result = false;
            }

            if (
                survey_copy.childs.has_childs === true &&
                this.surveyValidations.childs_dependencies !== true
            ) {
                result = false;
            }

            if (
                survey_copy.property_separation_status === true &&
                this.surveyValidations.property_separation_start_date !== true
            ) {
                result = false;
            }

            if (
                survey_copy.own_company.has_own_company === true &&
                survey_copy.own_company.is_suspended === false &&
                this.surveyValidations.own_company_start_date !== true
            ) {
                result = false;
            }

            if (
                survey_copy.own_company.has_own_company === true &&
                survey_copy.own_company.is_suspended === true &&
                this.surveyValidations.own_company_suspension_date !== true
            ) {
                result = false;
            }

            if (
                survey_copy.own_company.has_own_company === false &&
                survey_copy.own_company.has_ever_running === true &&
                this.surveyValidations.own_company_close_date !== true
            ) {
                result = false;
            }

            if (
                survey_copy.hired.is_hired === true &&
                this.surveyValidations.hired_employment_place !== true
            ) {
                result = false;
            }

            if (survey_copy.odd_job.is_hired === true && this.surveyValidations.odd_job !== true) {
                result = false;
            }

            if (
                survey_copy.l6m_income.has_income === true &&
                this.surveyValidations.l6m_income_items !== true
            ) {
                result = false;
            }

            if (
                survey_copy.unemployed_status.has_status === true &&
                this.surveyValidations.unemployed_status_grant_date !== true
            ) {
                result = false;
            }

            if (
                survey_copy.pandemic_impact.affected_employment === true &&
                this.surveyValidations.pandemic_impact_employment_description !== true
            ) {
                result = false;
            }

            if (
                survey_copy.retirement.is_retired === true &&
                this.surveyValidations.retirement_details !== true
            ) {
                result = false;
            }

            if (
                survey_copy.health_pension.is_pensioner === true &&
                this.surveyValidations.health_pension_details !== true
            ) {
                result = false;
            }

            if (
                survey_copy.disabled_person.is_disabled === true &&
                this.surveyValidations.disabled_person_details !== true
            ) {
                result = false;
            }

            if (
                survey_copy.pandemic_impact.affected_finances === true &&
                this.surveyValidations.pandemic_impact_finances_description !== true
            ) {
                result = false;
            }

            if (
                survey_copy.l12m_movable_property_transactions.transactions_made === true &&
                this.surveyValidations.l12m_movable_property_transactions !== true
            ) {
                result = false;
            }

            if (
                survey_copy.borrowed_funds_allocation.previous_loan_repayment !== "no" &&
                this.surveyValidations.borrowed_funds_allocation_previous_loan_repayment_amount !==
                    true
            ) {
                result = false;
            }

            if (
                survey_copy.borrowed_funds_allocation.bills_and_living !== "no" &&
                this.surveyValidations.borrowed_funds_allocation_bills_and_living_amount !== true
            ) {
                result = false;
            }

            if (
                survey_copy.borrowed_funds_allocation.treatment !== "no" &&
                this.surveyValidations.borrowed_funds_allocation_treatment_details !== true
            ) {
                result = false;
            }

            if (
                survey_copy.shareholding.debt_responsibility === true &&
                this.surveyValidations.shareholding_debt_responsibility_end_date !== true
            ) {
                result = false;
            }

            if (
                survey_copy.l10y_bankruptcy_application.application_submitted === true &&
                this.surveyValidations.l10y_bankruptcy_application_court_decision_date !== true
            ) {
                result = false;
            }

            if (
                survey_copy.l10y_bankruptcy_application.debt_cancellation === true &&
                this.surveyValidations.l10y_bankruptcy_application_debt_cancellation_amount !== true
            ) {
                result = false;
            }

            if (
                survey_copy.l10y_creditors_detriment === true &&
                this.surveyValidations.l10y_creditors_detriment_description !== true
            ) {
                result = false;
            }

            if (
                survey_copy.real_estate.ownership === "shared_ownership" &&
                this.surveyValidations.real_estate_co_owners !== true
            ) {
                result = false;
            }

            if (
                survey_copy.real_estate.ownership !== "no" &&
                this.surveyValidations.real_estate_details !== true
            ) {
                result = false;
            }

            if (
                survey_copy.flat_rent.is_renting === true &&
                this.surveyValidations.flat_rent_details !== true
            ) {
                result = false;
            }

            if (
                survey_copy.has_bank_accounts === true &&
                this.surveyValidations.bank_accounts !== true
            ) {
                result = false;
            }

            if (
                survey_copy.has_bank_deposits === true &&
                this.surveyValidations.bank_deposits !== true
            ) {
                result = false;
            }

            if (survey_copy.has_cash === true && this.surveyValidations.cash !== true) {
                result = false;
            }

            return result;
        },

        async sendSurveyData() {
            if (this.loading === true) return;
            const survey_copy = JSON.parse(JSON.stringify(this.survey));

            if (this.validateSummary() !== true) {
                this.$message({
                    type: "error",
                    title: "Błąd",
                    msg: "Sprawdź poprawność swoich danych"
                });
                this.isDisplayConfirmModal = false;
                return;
            }

            this.loading = true;

            try {
                if (survey_copy.other_residents.grandma === false) {
                    delete survey_copy.other_residents.no_of_grandmas;
                }
                if (survey_copy.other_residents.grandpa === false) {
                    delete survey_copy.other_residents.no_of_grandpas;
                }
                if (survey_copy.other_residents.son === false) {
                    delete survey_copy.other_residents.no_of_sons;
                }
                if (survey_copy.other_residents.daughter === false) {
                    delete survey_copy.other_residents.no_of_daughters;
                }
                if (survey_copy.other_residents.friend === false) {
                    delete survey_copy.other_residents.no_of_friends;
                }
                if (survey_copy.other_residents.female_friend === false) {
                    delete survey_copy.other_residents.no_of_female_friends;
                }

                if (
                    survey_copy.childs.has_childs === false ||
                    survey_copy.childs.sons.length === 0
                ) {
                    delete survey_copy.childs.sons;
                }
                if (
                    survey_copy.childs.has_childs === false ||
                    survey_copy.childs.daughters.length === 0
                ) {
                    delete survey_copy.childs.daughters;
                }

                if (survey_copy.is_married !== true) {
                    delete survey_copy.property_separation_status;
                    delete survey_copy.property_separation_start_date;
                }
                if (survey_copy.property_separation_status !== true) {
                    delete survey_copy.property_separation_start_date;
                }

                if (survey_copy.own_company.has_own_company === false) {
                    delete survey_copy.own_company.is_suspended;

                    if (survey_copy.own_company.has_ever_running === false) {
                        delete survey_copy.own_company.start_date;
                    }
                }
                if (survey_copy.own_company.has_own_company === true) {
                    delete survey_copy.own_company.has_ever_running;
                }
                if (
                    survey_copy.own_company.has_own_company !== true ||
                    survey_copy.own_company.is_suspended !== true
                ) {
                    delete survey_copy.own_company.suspension_date;
                }
                if (
                    survey_copy.own_company.has_own_company === true ||
                    survey_copy.own_company.has_ever_running !== true
                ) {
                    delete survey_copy.own_company.close_date;
                }

                if (survey_copy.hired.is_hired !== true) {
                    delete survey_copy.hired.company_name;
                    delete survey_copy.hired.company_address;
                    delete survey_copy.hired.company_nip;
                    delete survey_copy.hired.position;
                    delete survey_copy.hired.start_date;
                    delete survey_copy.hired.contract_type;
                    delete survey_copy.hired.salary;
                }

                if (survey_copy.odd_job.is_hired !== true) {
                    delete survey_copy.odd_job.income;
                }

                if (survey_copy.l6m_income.has_income !== true) {
                    delete survey_copy.l6m_income.items;
                } else if (Array.isArray(survey_copy.l6m_income.items)) {
                    survey_copy.l6m_income.items = survey_copy.l6m_income.items.map(item => {
                        const o = JSON.parse(JSON.stringify(item));
                        if (o.bailiff_seizure !== true) {
                            delete o.no_of_bailiffs;
                            delete o.seizure_size;
                        }
                        return o;
                    });
                }

                if (survey_copy.unemployed_status.has_status !== true) {
                    delete survey_copy.unemployed_status.status_grant_date;
                }

                if (survey_copy.pandemic_impact.affected_employment !== true) {
                    delete survey_copy.pandemic_impact.employment_affection_description;
                }

                if (survey_copy.pandemic_impact.other_financial_implications === "") {
                    delete survey_copy.pandemic_impact.other_financial_implications;
                }

                if (survey_copy.retirement.is_retired !== true) {
                    delete survey_copy.retirement.retirement_date;
                    delete survey_copy.retirement.amount;
                }

                if (survey_copy.health_pension.is_pensioner !== true) {
                    delete survey_copy.health_pension.reason;
                    delete survey_copy.health_pension.obtain_date;
                    delete survey_copy.health_pension.amount;
                }

                if (survey_copy.disabled_person.is_disabled !== true) {
                    delete survey_copy.disabled_person.disability_level;
                    delete survey_copy.disabled_person.description;
                    delete survey_copy.disabled_person.disability_status_date;
                }

                if (survey_copy.l12m_movable_property_transactions.transactions_made !== true) {
                    delete survey_copy.l12m_movable_property_transactions.transactions;
                }

                if (survey_copy.incurring_debts_moment.marriage !== true) {
                    delete survey_copy.incurring_debts_moment.spouse_job;
                }

                if (survey_copy.borrowed_funds_allocation.previous_loan_repayment === "no") {
                    delete survey_copy.borrowed_funds_allocation.previous_loan_repayment_amount;
                }

                if (survey_copy.borrowed_funds_allocation.bills_and_living === "no") {
                    delete survey_copy.borrowed_funds_allocation.bills_and_living_amount;
                }

                if (survey_copy.borrowed_funds_allocation.treatment === "no") {
                    delete survey_copy.borrowed_funds_allocation.treatment_monthly_amount;
                    delete survey_copy.borrowed_funds_allocation.treated_diseases;
                }

                if (survey_copy.shareholding.debt_responsibility !== true) {
                    delete survey_copy.shareholding.debt_responsibility_end_date;
                }

                if (survey_copy.l10y_bankruptcy_application.application_submitted !== true) {
                    delete survey_copy.l10y_bankruptcy_application.court_decision_date;
                    delete survey_copy.l10y_bankruptcy_application.debt_cancellation;
                }
                if (
                    survey_copy.l10y_bankruptcy_application.application_submitted !== true ||
                    survey_copy.l10y_bankruptcy_application.debt_cancellation !== true
                ) {
                    delete survey_copy.l10y_bankruptcy_application.debt_cancellation_amount;
                }

                if (survey_copy.l10y_creditors_detriment !== true) {
                    delete survey_copy.l10y_creditors_detriment_description;
                }

                if (survey_copy.real_estate.ownership !== "shared_ownership") {
                    delete survey_copy.real_estate.co_owners;
                    delete survey_copy.real_estate.ownership_percentage;
                }
                if (survey_copy.real_estate.ownership === "no") {
                    delete survey_copy.real_estate.property_value;
                }

                if (survey_copy.flat_rent.is_renting !== true) {
                    delete survey_copy.flat_rent.rent_price;
                    delete survey_copy.flat_rent.flat_size;
                }

                if (survey_copy.has_bank_accounts !== true) {
                    delete survey_copy.bank_accounts;
                }

                if (survey_copy.has_bank_deposits !== true) {
                    delete survey_copy.bank_deposits;
                }

                if (survey_copy.has_cash !== true) {
                    delete survey_copy.cash;
                }

                await this.$axios.$put(
                    `/applications/${this.$store.getters["application/getApplication"]._id}/survey`,
                    survey_copy
                );

                await this.$axios.$post(
                    `/applications/${this.$store.getters["application/getApplication"]._id}/confirm-survey`
                );

                this.$message({
                    type: "success",
                    title: "Sukces",
                    msg: "Dziękujemy, ankieta została zapisana pomyślnie"
                });

                removeFromLocalStorage(`app_${this.app._id}_survey`);
                removeFromLocalStorage(`app_${this.app._id}_survey_active_question`);
                removeFromLocalStorage(`survey_validations`);

                this.$store.commit("setForceHideHint", false);

                this.$router.push({
                    name: "property-components"
                });

                this.isDisplayConfirmModal = false;
            } catch (err) {
                console.error(err);
            }

            this.loading = false;
        },

        displayOtherResidents() {
            const exp_arr = {
                daughter: "no_of_daughters",
                friend: "no_of_friends",
                female_friend: "no_of_female_friends",
                grandma: "no_of_grandmas",
                grandpa: "no_of_grandpas",
                son: "no_of_sons"
            };
            const residents_map = {
                wife: "żona",
                husband: "mąż",
                ex_wife: "była żona",
                ex_husband: "były mąż",
                partner: "partner",
                female_partner: "partnerka",
                father: "ojciec",
                mother: "matka",
                grandma: "babcia",
                grandpa: "dziadek",
                father_in_law: "teść",
                mother_in_law: "teściowa",
                son: "syn",
                daughter: "córka",
                friend: "przyjaciel",
                female_friend: "przyjaciółka"
            };

            let result = "";
            for (const [key, value] of Object.entries(this.survey.other_residents)) {
                if (value === true) result += `${residents_map[key]}, `;
                if (Object.keys(exp_arr).includes(key) && value === true) {
                    result = result.slice(0, -2);
                    result += ` (${this.survey.other_residents[exp_arr[key]]}), `;
                }
            }
            return result.slice(0, -2);
        },

        displayChildsCount() {
            return `Synowie: ${this.value.childs.sons.length} Córki: ${this.value.childs.daughters.length}`;
        },

        displayChildsDependencies() {
            let result = [];

            for (let i = 0; i < this.survey.childs.sons.length; i++) {
                result.push(
                    `Syn #${i + 1}: ${
                        this.survey.childs.sons[i].is_depend === true
                            ? "Na moim utrzymaniu"
                            : this.survey.childs.sons[i].is_depend === false
                            ? "Samodzielny finansowo"
                            : "b/d"
                    }`
                );
            }
            for (let i = 0; i < this.survey.childs.daughters.length; i++) {
                result.push(
                    `Córka #${i + 1}: ${
                        this.survey.childs.daughters[i].is_depend === true
                            ? "Na moim utrzymaniu"
                            : this.survey.childs.daughters[i].is_depend === false
                            ? "Samodzielna finansowo"
                            : "b/d"
                    }`
                );
            }

            return result.join("; ");
        },

        displayDisabledPersonDetails() {
            let result = "";

            const disability_level_map = {
                light: "lekki",
                moderate: "umiarkowany",
                considerable: "znaczny"
            };

            result += `Stopień ${
                disability_level_map[this.survey.disabled_person.disability_level]
            } (od ${revData(this.survey.disabled_person.disability_status_date)}) Opis: ${
                this.survey.disabled_person.description
            }`;

            return result;
        },

        displayFinancesAffectionDetails() {
            let result = [];

            if (this.survey.pandemic_impact.caused_job_lost === true) {
                result.push("Utrata pracy");
            }
            if (this.survey.pandemic_impact.caused_salary_loss === true) {
                result.push("Utrata wynagrodzenia");
            }
            if (this.survey.pandemic_impact.caused_salary_reduction === true) {
                result.push("Zmniejszenie wynagrodzenia");
            }
            if (this.survey.pandemic_impact.other_financial_implications.length > 0) {
                result.push(this.survey.pandemic_impact.other_financial_implications);
            }

            return result.join("; ");
        },

        displayIncurringDebtsMomentCreditwrothiness() {
            if (this.survey.incurring_debts_moment.creditworthiness === "yes") return "Tak";
            if (this.survey.incurring_debts_moment.creditworthiness === "no") return "Nie";
            else return "Nie wiem";
        },

        displayBorrowedFundsAllocationPreviousLoanRepayment() {
            if (this.survey.borrowed_funds_allocation.previous_loan_repayment === "yes")
                return "Tak, w całości";
            if (this.survey.borrowed_funds_allocation.previous_loan_repayment === "no")
                return "Nie zostały przeznaczone";
            else return "Tak, częściowo";
        },

        displayBorrowedFundsAllocationBillsAndLiving() {
            if (this.survey.borrowed_funds_allocation.bills_and_living === "yes")
                return "Tak, w całości";
            if (this.survey.borrowed_funds_allocation.bills_and_living === "no")
                return "Nie zostały przeznaczone";
            else return "Tak, częściowo";
        },

        displayBorrowedFundsAllocationTreatment() {
            if (this.survey.borrowed_funds_allocation.treatment === "yes") return "Tak, w całości";
            if (this.survey.borrowed_funds_allocation.treatment === "no")
                return "Nie zostały przeznaczone";
            else return "Tak, częściowo";
        },

        displayRealEstateOwnership() {
            if (this.survey.real_estate.ownership === "yes")
                return "Tak, jestem jedynym właścicielem";
            if (this.survey.real_estate.ownership === "no") return "Nie jestem właścicielem";
            else return "Tak, jestem współwłaścicielem";
        },

        closeConfirmDataModal() {
            this.isDisplayConfirmModal = false;
        }
    }
};
