//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "../mixins";
import PpDataField from "../PpDataField.vue";
import SurveyCashFormElement from "./SurveyCashFormElement.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpDataField,
        SurveyCashFormElement
    },

    data() {
        return {
            is_display_modal: false
        };
    },

    methods: {
        openModal() {
            this.is_display_modal = true;
        },
        closeModal() {
            this.is_display_modal = false;
        },

        addBankCash() {
            if (this.$refs.cfe.validate() === true) {
                this.survey.cash.push(this.$refs.cfe.saveData());
                this.emitUpdate();
                this.closeModal();
            }
        },

        deleteCash(index) {
            this.survey.cash.splice(index, 1);
            this.emitUpdate();
        },

        getCashInfo(cash_details) {
            return `${cash_details.cash_amount} ${cash_details.currency} • ${this.$store.getters[
                "displayGeneralAddress"
            ](cash_details.address)}`;
        },

        validate() {
            let result = true;

            if (this.survey.cash.length === 0) {
                result = "Dodaj co najmniej jedno źródło gotówki";
            }

            if (this.survey.cash.length > 127) {
                result = "Możesz dodać maksymalnie 127 źródeł gotówki";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "cash",
                value: result
            });

            return result;
        }
    }
};
