var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"bank_name",attrs:{"type":"text","label":"Nazwa banku","show-asterisk":"","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    else if (v.length > 63) { return 'Maksymalna długość nazwy banku to 63 znaki'; }
                    return true;
                }
            ]},model:{value:(_vm.local_data.bank_name),callback:function ($$v) {_vm.$set(_vm.local_data, "bank_name", $$v)},expression:"local_data.bank_name"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"funds_amount",attrs:{"type":"number","label":"Wartość salda","rules":[
                function (v) {
                    if (v > _vm.$store.state.MAX_SAFE_INTEGER)
                        { return 'Maksymalna kwota została przekroczona'; }
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v < 0) { return 'Wartość salda nie może być niższa od 0'; }
                    return true;
                }
            ],"show-asterisk":""},model:{value:(_vm.local_data.deposit_amount),callback:function ($$v) {_vm.$set(_vm.local_data, "deposit_amount", _vm._n($$v))},expression:"local_data.deposit_amount"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkSelect',{ref:"country_select",attrs:{"label":"Waluta","placeholder":"Wybierz walutę","show-asterisk":"","items":_vm.currencySelectItems,"empty-list-msg":"Waluty nie ma na liście"},on:{"change":function($event){_vm.currency_select_search = ''}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"survey__searchbar-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currency_select_search),expression:"currency_select_search"}],staticClass:"survey__searchbar",attrs:{"type":"text","placeholder":"Szukaj","tabindex":"-1"},domProps:{"value":(_vm.currency_select_search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currency_select_search=$event.target.value}}})])]},proxy:true}]),model:{value:(_vm.local_data.currency),callback:function ($$v) {_vm.$set(_vm.local_data, "currency", $$v)},expression:"local_data.currency"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }