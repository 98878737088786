var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"udfe"},[_c('div',[_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"company_name",attrs:{"label":"Nazwa firmy","show-asterisk":"","placeholder":"Nazwa firmy","rules":[
                    function (v) {
                        if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                        else if (v.length > 63)
                            { return 'Maksymalna długość nazwy firmy to 63 znaki'; }
                        return true;
                    }
                ]},on:{"input":_vm.emitUpdate},model:{value:(_vm.survey[_vm.employmentType].company_name),callback:function ($$v) {_vm.$set(_vm.survey[_vm.employmentType], "company_name", $$v)},expression:"survey[employmentType].company_name"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"company_address",attrs:{"label":"Adres firmy","show-asterisk":"","placeholder":"Adres firmy","rules":[
                    function (v) {
                        if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                        else if (v.length > 255)
                            { return 'Maksymalna długość adresu firmy to 255 znaków'; }
                        return true;
                    }
                ]},on:{"input":_vm.emitUpdate},model:{value:(_vm.survey[_vm.employmentType].company_address),callback:function ($$v) {_vm.$set(_vm.survey[_vm.employmentType], "company_address", $$v)},expression:"survey[employmentType].company_address"}})],1),_c('div',{staticClass:"survey__input-wrapper"},[_c('div',{staticClass:"survey__input-cell mr-6"},[_c('WkTextField',{ref:"company_nip",attrs:{"label":"Nip firmy","show-asterisk":"","placeholder":"NIP","rules":[
                        function (v) {
                            if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                            else if (!this$1.$store.getters.VALIDATOR_TAX_NUMBER_PL(v))
                                { return 'Proszę podać prawidłowy numer NIP'; }
                            return true;
                        }
                    ]},on:{"input":_vm.emitUpdate},model:{value:(_vm.survey[_vm.employmentType].company_nip),callback:function ($$v) {_vm.$set(_vm.survey[_vm.employmentType], "company_nip", $$v)},expression:"survey[employmentType].company_nip"}})],1),_c('div',{staticClass:"survey__input-cell"},[_c('PpComboDateInput',{ref:"start_date",attrs:{"label":"Data rozpoczęcia","show-asterisk":"","rules":[
                        function (v) {
                            var date = new Date(v);
                            if (date.getTime() > Date.now()) {
                                return 'Proszę podać datę z przeszłości';
                            }
                            return true;
                        }
                    ]},on:{"input":_vm.emitUpdate},model:{value:(_vm.survey[_vm.employmentType].start_date),callback:function ($$v) {_vm.$set(_vm.survey[_vm.employmentType], "start_date", $$v)},expression:"survey[employmentType].start_date"}})],1)]),_c('div',{staticClass:"survey__input-wrapper"},[_c('div',{staticClass:"survey__input-cell mr-6"},[_c('WkSelect',{attrs:{"label":"Rodzaj umowy","placeholder":"Wybierz opcję","show-asterisk":"","items":[
                        { text: 'Umowa o pracę', value: 'uop' },
                        { text: 'Umowa zlecenie', value: 'uz' },
                        { text: 'Umowa o dzieło', value: 'uod' },
                        { text: 'Umowa B2B', value: 'b2b' }
                    ]},on:{"change":_vm.emitUpdate},model:{value:(_vm.survey[_vm.employmentType].contract_type),callback:function ($$v) {_vm.$set(_vm.survey[_vm.employmentType], "contract_type", $$v)},expression:"survey[employmentType].contract_type"}})],1),_c('div',{staticClass:"survey__input-cell"},[_c('WkTextField',{ref:"salary",attrs:{"label":"Wynagrodzenie","show-asterisk":"","placeholder":"Kwota brutto","rules":[
                        function (v) {
                            if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                            if (v.length > 31)
                                { return 'Maksymalna długość kwoty wynagrodzenia to 31 znaków'; }
                            if (!this$1.$store.state.REGEX_IS_MONEY.test(v))
                                { return 'Kwota musi zawierać liczbę'; }
                            return true;
                        }
                    ]},on:{"input":_vm.emitUpdate},model:{value:(_vm.survey[_vm.employmentType].salary),callback:function ($$v) {_vm.$set(_vm.survey[_vm.employmentType], "salary", $$v)},expression:"survey[employmentType].salary"}})],1)]),_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"position",attrs:{"label":"Stanowisko","show-asterisk":"","placeholder":"Stanowisko","rules":[
                    function (v) {
                        if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                        else if (v.length > 63)
                            { return 'Maksymalna długość stanowiska to 63 znaki'; }
                        return true;
                    }
                ]},on:{"input":_vm.emitUpdate},model:{value:(_vm.survey[_vm.employmentType].position),callback:function ($$v) {_vm.$set(_vm.survey[_vm.employmentType], "position", $$v)},expression:"survey[employmentType].position"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }