var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.survey.l12m_movable_property_transactions.transactions.length === 0)?_c('div',{staticClass:"survey__empty-placeholder"},[_vm._v(" Nie dodano transakcji ")]):_vm._e(),_vm._l((_vm.survey.l12m_movable_property_transactions.transactions),function(transaction,index){return _c('PpDataField',{key:index,ref:'df_' + index,refInFor:true,attrs:{"delete-title":"Usuwanie sprzedaży/darowizny","title":"Edytuj transakcję","value":transaction},on:{"delete":function($event){return _vm.deleteTransaction(index)},"update":function () {
                if (_vm.$refs['tfe_' + index][0].validate() === true) {
                    _vm.survey.l12m_movable_property_transactions.transactions.splice(
                        index,
                        1,
                        _vm.$refs['tfe_' + index][0].saveData()
                    );
                    _vm.emitUpdate();
                    _vm.$refs['df_' + index][0].closeEditModal();
                }
            }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"data-field__title"},[_vm._v(" "+_vm._s(transaction.transaction_subject)+" ")]),_c('div',{staticClass:"data-field__text-content"},[_vm._v(" "+_vm._s(_vm.getTransactionInfo(transaction))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('SurveyTransactionFormElement',{ref:'tfe_' + index,refInFor:true,attrs:{"value":transaction}})]},proxy:true}],null,true)})}),_c('PpButton',{staticClass:"pp-button--secondary pp-button--text small pl-0",on:{"click":_vm.openModal}},[_c('PpIcon',{attrs:{"name":"plus-circle"}}),_vm._v(" Dodaj kolejną transakcję")],1),_c('PpDialog',{attrs:{"show":_vm.is_display_modal,"title":"Dodaj transakcję","maxWidth":450,"cancelable":false},on:{"close":_vm.closeModal}},[_c('SurveyTransactionFormElement',{ref:"tfe",attrs:{"label":"Dodaj nową transakcję"}}),_c('PpDialogFooter',[_c('PpButton',{attrs:{"text":""},on:{"click":_vm.closeModal}},[_vm._v("Anuluj")]),_c('PpButton',{on:{"click":_vm.addTransaction}},[_vm._v("Dodaj")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }