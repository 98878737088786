//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "../mixins";
import PpComboDateInput from "@/components/PpComboDateInput.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpComboDateInput
    },

    methods: {
        validate() {
            let result = true;

            const validations = [this.$refs.start_date.validate()];

            if (validations.includes(false)) {
                result = "Proszę poprawić błędy w formularzu";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "own_company_start_date",
                value: result
            });

            return result;
        }
    }
};
