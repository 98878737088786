var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.survey.l6m_income.items.length === 0)?_c('div',{staticClass:"survey__empty-placeholder"},[_vm._v(" Nie dodano źródeł przychodu ")]):_vm._e(),_vm._l((_vm.survey.l6m_income.items),function(source,index){return _c('PpDataField',{key:index,ref:'df_' + index,refInFor:true,attrs:{"delete-title":"Usuwanie źródła przychodu","title":"Edytuj źródło przychodu","value":source},on:{"delete":function($event){return _vm.deleteSource(index)},"update":function () {
                if (_vm.$refs['ife_' + index][0].validate() === true) {
                    _vm.survey.l6m_income.items.splice(
                        index,
                        1,
                        _vm.$refs['ife_' + index][0].saveData()
                    );
                    _vm.emitUpdate();
                    _vm.$refs['df_' + index][0].closeEditModal();
                }
            }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"data-field__title"},[_vm._v(" "+_vm._s(source.income_source)+" ")]),_c('div',{staticClass:"data-field__text-content"},[_vm._v(" "+_vm._s(_vm.getSourceInfo(source))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('SurveyIncomeSourcesFormElement',{ref:'ife_' + index,refInFor:true,attrs:{"value":source}})]},proxy:true}],null,true)})}),_c('PpButton',{staticClass:"pp-button--secondary pp-button--text small pl-0",on:{"click":_vm.openModal}},[_c('PpIcon',{attrs:{"name":"plus-circle"}}),_vm._v(" Dodaj kolejny przychód")],1),_c('PpDialog',{attrs:{"show":_vm.is_display_modal,"title":"Dodaj źródło przychodu","maxWidth":450,"cancelable":false},on:{"close":_vm.closeModal}},[_c('SurveyIncomeSourcesFormElement',{ref:"isfe",attrs:{"label":"Dodaj źródło przychodu"}}),_c('PpDialogFooter',[_c('PpButton',{attrs:{"text":""},on:{"click":_vm.closeModal}},[_vm._v("Anuluj")]),_c('PpButton',{on:{"click":_vm.addIncomeSource}},[_vm._v("Dodaj")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }