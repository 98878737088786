//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "@/components/mixins";
import PpComboDateInput from "@/components//PpComboDateInput.vue";

export default {
    mixins: [updateSurveyData],

    props: {
        singleColumn: {
            type: Boolean,
            default: false
        }
    },

    components: {
        PpComboDateInput
    },

    methods: {
        validate() {
            let result = true;

            const validations = [
                this.$refs.health_pension_obtain_date.validate(),
                this.$refs.health_pension_amount.validate(),
                this.$refs.health_pension_reason.validate()
            ];

            if (validations.includes(false)) {
                result = "Proszę poprawić błędy w formularzu";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "health_pension_details",
                value: result
            });

            return result;
        }
    }
};
