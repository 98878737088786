//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        label: {
            type: String
        },
        value: {
            type: Object
        }
    },

    data() {
        return {
            local_source: {
                income_source: "",
                avg_income: "",
                bailiff_seizure: false,
                no_of_bailiffs: "",
                seizure_size: "",
                additional_info: ""
            }
        };
    },

    watch: {
        value() {
            this.handleValue();
        }
    },

    methods: {
        saveData() {
            return this.local_source;
        },
        handleValue() {
            if (this.value) {
                this.local_source = { ...this.value };
            }
        },
        validate() {
            const validations = [
                this.$refs.income_source.validate(),
                this.$refs.avg_income.validate(),
                this.$refs.additional_info.validate()
            ];

            if (this.local_source.bailiff_seizure === true) {
                validations.push(this.$refs.no_of_bailiffs.validate());
                validations.push(this.$refs.seizure_size.validate());
            }

            if (validations.includes(false)) {
                return false;
            }

            return true;
        }
    },

    mounted() {
        this.handleValue();
    }
};
