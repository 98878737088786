//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const ICONS_MAP = Object.freeze({
    info: "info-circle",
    success: "check-circle",
    error: "exclamation-circle"
});
const TYPES = ["info", "success", "error"];

export default {
    props: {
        type: {
            type: String,
            default: "info",
            validator: function (v) {
                return TYPES.indexOf(v) !== -1;
            }
        },
        title: {
            type: String
        },
        text: {
            type: String
        },
        icon: {
            type: String
        }
    },

    computed: {
        hasTitleSlot() {
            return !!this.$slots.title;
        },
        hasDefaultSlot() {
            return !!this.$slots.default;
        },
        iconName() {
            if (this.icon) return this.icon;
            return ICONS_MAP[this.type];
        }
    }
};
