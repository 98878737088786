export const updateDebtorData = {
    data() {
        return {
            debtor_personal_data: {},
            debtor_personal_data_validations: {}
        };
    },

    props: {
        value: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    computed: {
        app_store() {
            return this.$store.getters["application/getApplication"];
        }
    },

    methods: {
        emitUpdate(upd) {
            this.$store.commit("formData/setDebtorData", upd);
        },

        emitValidationUpdate(upd) {
            this.$store.commit("formData/setDebtorDataValidation", upd);
        },

        getValue() {
            return this.debtor_personal_data;
        },

        valueExistence(v) {
            if (
                this.app_store.debtor_personal_data[v] !== "" &&
                this.app_store.debtor_personal_data[v] !== undefined &&
                this.debtor_personal_data[v] === ""
            ) {
                this.debtor_personal_data[v] = this.app_store.debtor_personal_data[v];
            }
        },

        addressIsEmpty(address) {
            return (
                address.street == "" &&
                address.house_no == "" &&
                address.zip_code == "" &&
                address.city == "" &&
                (address.apartment_no == undefined || address.apartment_no == "")
            );
        }
    },

    async created() {
        if (!this.$store.state.initial_layout_set) return;

        this.debtor_personal_data = JSON.parse(
            JSON.stringify(this.$store.state.formData.debtor_personal_data)
        );
        this.debtor_personal_data_validations = JSON.parse(
            JSON.stringify(this.$store.state.formData.debtor_personal_data_validations)
        );

        await this.$store.dispatch("application/awaitForApplication");

        if (this.app_store.debtor_personal_data !== undefined) {
            this.valueExistence("name");
            this.valueExistence("email");
            this.valueExistence("pesel");

            if (this.app_store.debtor_personal_data.has_nip !== undefined) {
                this.debtor_personal_data.has_nip = this.app_store.debtor_personal_data.has_nip;
            }

            this.valueExistence("nip");
            this.valueExistence("phone");
            this.valueExistence("birth_date");
            // jeżeli nie ma b_date, ale jest PESEL to robimy ekstrakt
            if (
                this.debtor_personal_data["birth_date"] == "" &&
                this.debtor_personal_data["pesel"] != ""
            ) {
                this.debtor_personal_data["birth_date"] = this.$store.getters[
                    "getBirthDateFromPESEL"
                ](this.debtor_personal_data["pesel"]);
            }

            if (
                this.app_store.debtor_personal_data.address !== undefined &&
                this.addressIsEmpty(this.debtor_personal_data.address)
            ) {
                this.debtor_personal_data.address = JSON.parse(
                    JSON.stringify(this.app_store.debtor_personal_data.address)
                );
                if (this.debtor_personal_data.address.apartment_no == undefined)
                    this.debtor_personal_data.address.apartment_no = "";
            }

            if (this.app_store.debtor_personal_data.billing_address_same_as_address !== undefined) {
                this.debtor_personal_data.billing_address_same_as_address =
                    this.app_store.debtor_personal_data.billing_address_same_as_address;
            }

            if (
                this.app_store.debtor_personal_data.billing_address !== undefined &&
                this.addressIsEmpty(this.debtor_personal_data.billing_address)
            ) {
                this.debtor_personal_data.billing_address = JSON.parse(
                    JSON.stringify(this.app_store.debtor_personal_data.billing_address)
                );
                if (this.debtor_personal_data.billing_address.apartment_no == undefined)
                    this.debtor_personal_data.billing_address.apartment_no = "";
            }

            this.emitUpdate(this.debtor_personal_data);
        }
    }
};

export const updateSurveyData = {
    data() {
        return {
            survey: {}
        };
    },

    props: {
        value: {
            type: Object,
            default() {
                return {};
            }
        },
        validateOnMount: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        emitUpdate() {
            this.$emit("input", this.survey);
        },

        handleValue() {
            this.survey = JSON.parse(JSON.stringify(this.value));
        }
    },

    watch: {
        value: {
            deep: true,
            handler() {
                this.handleValue();
            }
        }
    },

    created() {
        this.handleValue();
    },

    mounted() {
        if (this.validateOnMount && typeof this.validate == "function") {
            this.$nextTick(this.validate);
        }
    }
};
