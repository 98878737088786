var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"udfe"},[_c('div',{staticClass:"survey__input-wrapper"},[_c('div',{staticClass:"survey__input-cell mr-6"},[_c('PpStepperInput',{ref:"no_of_sons",staticClass:"mr-6",attrs:{"label":"Synowie","rules":[
                    function (v) {
                        if (v < 0) { return 'Liczba dzieci musi być większa od 0'; }
                        if (v > 30) { return 'Liczba dzieci nie może być wyższa od 30'; }
                        return true;
                    },
                    function (v) {
                        if (v !== 0 || _vm.survey.childs.daughters.length > 0) {
                            return true;
                        } else {
                            return 'Proszę dodać conajmniej jedno dziecko';
                        }
                    }
                ],"min":0,"max":30},on:{"input":function($event){return _vm.handleChild('sons')}},model:{value:(_vm.sons_amount),callback:function ($$v) {_vm.sons_amount=$$v},expression:"sons_amount"}})],1),_c('div',{staticClass:"survey__input-cell"},[_c('PpStepperInput',{ref:"no_of_daughters",attrs:{"label":"Córki","rules":[
                    function (v) {
                        if (v < 0) { return 'Liczba dzieci musi być większa od 0'; }
                        if (v > 30) { return 'Liczba dzieci nie może być wyższa od 30'; }
                        return true;
                    },
                    function (v) {
                        if (v !== 0 || _vm.survey.childs.sons.length > 0) {
                            return true;
                        } else {
                            return 'Proszę podać co najmniej jedno dziecko';
                        }
                    }
                ],"min":0,"max":30},on:{"input":function($event){return _vm.handleChild('daughters')}},model:{value:(_vm.daughters_amount),callback:function ($$v) {_vm.daughters_amount=$$v},expression:"daughters_amount"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }