//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { saveInLocalStorage, retrieveFromLocalStorage } from "../helpers/local-storage";
import PpRadioButton from "../components/PpRadioButton.vue";
import SurveyLivingAlone from "../components/SurveyFormElements/SurveyLivingAlone.vue";
import SurveyOtherResidents from "../components/SurveyFormElements/SurveyOtherResidents.vue";
import SurveyChildsHasChilds from "../components/SurveyFormElements/SurveyChildsHasChilds.vue";
import SurveyIsMarried from "../components/SurveyFormElements/SurveyIsMarried.vue";
import SurveyPropertySeparationStatus from "../components/SurveyFormElements/SurveyPropertySeparationStatus.vue";
import SurveyPropertySeparationStartDate from "../components/SurveyFormElements/SurveyPropertySeparationStartDate.vue";
import SurveyOwnCompanyHasOwnCompany from "../components/SurveyFormElements/SurveyOwnCompanyHasOwnCompany.vue";
import SurveyIsDivorced from "../components/SurveyFormElements/SurveyIsDivorced.vue";
import SurveyChildsCount from "../components/SurveyFormElements/SurveyChildsCount.vue";
import SurveyChildsDependencies from "../components/SurveyFormElements/SurveyChildsDependencies.vue";
import SurveyOwnCompanyStartDate from "../components/SurveyFormElements/SurveyOwnCompanyStartDate.vue";
import SurveyHiredIsHired from "../components/SurveyFormElements/SurveyHiredIsHired.vue";
import SurveyOwnCompanyHasEverRunning from "../components/SurveyFormElements/SurveyOwnCompanyHasEverRunning.vue";
import SurveyOwnCompanySuspensionDate from "../components/SurveyFormElements/SurveyOwnCompanySuspensionDate.vue";
import SurveyOwnCompanyCloseDate from "../components/SurveyFormElements/SurveyOwnCompanyCloseDate.vue";
import SurveyEmploymentPlace from "../components/SurveyFormElements/SurveyEmploymentPlace.vue";
import SurveyOddJob from "../components/SurveyFormElements/SurveyOddJob.vue";
import SurveyLast6MonthsIncomeHasIncome from "../components/SurveyFormElements/SurveyLast6MonthsIncomeHasIncome.vue";
import SurveyOwnCompanyIsSuspended from "../components/SurveyFormElements/SurveyOwnCompanyIsSuspended.vue";
import SurveyUnemployedStatusHasStatus from "../components/SurveyFormElements/SurveyUnemployedStatusHasStatus.vue";
import SurveyAddIncomeSources from "../components/SurveyFormElements/SurveyAddIncomeSources.vue";
import SurveyUnemployedStatusStatusGrantDate from "../components/SurveyFormElements/SurveyUnemployedStatusStatusGrantDate.vue";
import SurveyPandemicImpactAffectedEmployment from "../components/SurveyFormElements/SurveyPandemicImpactAffectedEmployment.vue";
import SurveyPandemicImpactAffectedFinances from "../components/SurveyFormElements/SurveyPandemicImpactAffectedFinances.vue";
import SurveyRetirementIsRetired from "../components/SurveyFormElements/SurveyRetirementIsRetired.vue";
import SurveyRetirementDetails from "../components/SurveyFormElements/SurveyRetirementDetails.vue";
import SurveyHealthPensionIsPensioner from "../components/SurveyFormElements/SurveyHealthPensionIsPensioner.vue";
import SurveyHealthPensionDetails from "../components/SurveyFormElements/SurveyHealthPensionDetails.vue";
import SurveyDisabledPersonIsDisabled from "../components/SurveyFormElements/SurveyDisabledPersonIsDisabled.vue";
import SurveyDisabledPersonDetails from "../components/SurveyFormElements/SurveyDisabledPersonDetails.vue";
import SurveyPandemicImpactEmploymentAffectionDescription from "../components/SurveyFormElements/SurveyPandemicImpactEmploymentAffectionDescription.vue";
import SurveyPandemicImpactFinancesAffectionDetails from "../components/SurveyFormElements/SurveyPandemicImpactFinancesAffectionDetails.vue";
import SurveyLast12MonthsMovablePropertyTransactionsTransactionsMade from "../components/SurveyFormElements/SurveyLast12MonthsMovablePropertyTransactionsTransactionsMade.vue";
import SurveyAddTransactionsOnMovables from "../components/SurveyFormElements/SurveyAddTransactionsOnMovables.vue";
import SurveyIncurringDebtsMomentCreditworthiness from "../components/SurveyFormElements/SurveyIncurringDebtsMomentCreditworthiness.vue";
import SurveyIncurringDebtsMomentJob from "../components/SurveyFormElements/SurveyIncurringDebtsMomentJob.vue";
import SurveyIncurringDebtsMomentMarriage from "../components/SurveyFormElements/SurveyIncurringDebtsMomentMarriage.vue";
import SurveyIncurringDebtsMomentSpouseJob from "../components/SurveyFormElements/SurveyIncurringDebtsMomentSpouseJob.vue";
import SurveyBorrowedFundsAllocationPreviousLoanRepayment from "../components/SurveyFormElements/SurveyBorrowedFundsAllocationPreviousLoanRepayment.vue";
import SurveyBorrowedFundsAllocationPreviousLoanRepaymentAmount from "../components/SurveyFormElements/SurveyBorrowedFundsAllocationPreviousLoanRepaymentAmount.vue";
import SurveyBorrowedFundsAllocationBillsAndLiving from "../components/SurveyFormElements/SurveyBorrowedFundsAllocationBillsAndLiving.vue";
import SurveyBorrowedFundsAllocationBillsAndLivingAmount from "../components/SurveyFormElements/SurveyBorrowedFundsAllocationBillsAndLivingAmount.vue";
import SurveyBorrowedFundsAllocationTreatment from "../components/SurveyFormElements/SurveyBorrowedFundsAllocationTreatment.vue";
import SurveyBorrowedFundsAllocationTreatmentDetails from "../components/SurveyFormElements/SurveyBorrowedFundsAllocationTreatmentDetails.vue";
import SurveyShareholdingDebtResponsibility from "../components/SurveyFormElements/SurveyShareholdingDebtResponsibility.vue";
import SurveyShareholdingDebtResponsibilityEndDate from "../components/SurveyFormElements/SurveyShareholdingDebtResponsibilityEndDate.vue";
import SurveyLast10YearsBankruptcyApplicationApplicationSubmitted from "../components/SurveyFormElements/SurveyLast10YearsBankruptcyApplicationApplicationSubmitted.vue";
import SurveyLast10YearsBankruptcyApplicationCourtDecisionDate from "../components/SurveyFormElements/SurveyLast10YearsBankruptcyApplicationCourtDecisionDate.vue";
import SurveyLast10YearsBankruptcyApplicationDebtCancellation from "../components/SurveyFormElements/SurveyLast10YearsBankruptcyApplicationDebtCancellation.vue";
import SurveyLast10YearsBankruptcyApplicationDebtCancellationAmount from "../components/SurveyFormElements/SurveyLast10YearsBankruptcyApplicationDebtCancellationAmount.vue";
import SurveyLast10YearsCreditorsDetriment from "../components/SurveyFormElements/SurveyLast10YearsCreditorsDetriment.vue";
import SurveyLast10YearsCreditorsDetrimentDescription from "../components/SurveyFormElements/SurveyLast10YearsCreditorsDetrimentDescription.vue";
import SurveyRealEstateOwnership from "../components/SurveyFormElements/SurveyRealEstateOwnership.vue";
import SurveyRealEstateCoOwners from "../components/SurveyFormElements/SurveyRealEstateCoOwners.vue";
import SurveyRealEstateDetails from "../components/SurveyFormElements/SurveyRealEstateDetails.vue";
import SurveyFlatRentIsRenting from "../components/SurveyFormElements/SurveyFlatRentIsRenting.vue";
import SurveyFlatRentDetails from "../components/SurveyFormElements/SurveyFlatRentDetails.vue";
import SurveyHasBankAccounts from "../components/SurveyFormElements/SurveyHasBankAccounts.vue";
import SurveyAddBankAccounts from "../components/SurveyFormElements/SurveyAddBankAccounts.vue";
import SurveyHasBankDeposits from "../components/SurveyFormElements/SurveyHasBankDeposits.vue";
import SurveyAddBankDeposits from "../components/SurveyFormElements/SurveyAddBankDeposits.vue";
import SurveyHasCash from "../components/SurveyFormElements/SurveyHasCash.vue";
import SurveyAddCash from "../components/SurveyFormElements/SurveyAddCash.vue";
import SurveyDataConfirmation from "../components/SurveyFormElements/SurveyDataConfirmation.vue";
export default {
    components: {
        PpRadioButton,
        SurveyLivingAlone,
        SurveyOtherResidents,
        SurveyChildsHasChilds,
        SurveyIsMarried,
        SurveyPropertySeparationStatus,
        SurveyPropertySeparationStartDate,
        SurveyOwnCompanyHasOwnCompany,
        SurveyIsDivorced,
        SurveyChildsCount,
        SurveyChildsDependencies,
        SurveyOwnCompanyStartDate,
        SurveyHiredIsHired,
        SurveyOwnCompanyHasEverRunning,
        SurveyOwnCompanySuspensionDate,
        SurveyOwnCompanyCloseDate,
        SurveyEmploymentPlace,
        SurveyOddJob,
        SurveyLast6MonthsIncomeHasIncome,
        SurveyOwnCompanyIsSuspended,
        SurveyUnemployedStatusHasStatus,
        SurveyAddIncomeSources,
        SurveyUnemployedStatusStatusGrantDate,
        SurveyPandemicImpactAffectedEmployment,
        SurveyPandemicImpactAffectedFinances,
        SurveyRetirementIsRetired,
        SurveyRetirementDetails,
        SurveyHealthPensionIsPensioner,
        SurveyHealthPensionDetails,
        SurveyDisabledPersonIsDisabled,
        SurveyDisabledPersonDetails,
        SurveyPandemicImpactEmploymentAffectionDescription,
        SurveyPandemicImpactFinancesAffectionDetails,
        SurveyLast12MonthsMovablePropertyTransactionsTransactionsMade,
        SurveyAddTransactionsOnMovables,
        SurveyIncurringDebtsMomentCreditworthiness,
        SurveyIncurringDebtsMomentJob,
        SurveyIncurringDebtsMomentMarriage,
        SurveyIncurringDebtsMomentSpouseJob,
        SurveyBorrowedFundsAllocationPreviousLoanRepayment,
        SurveyBorrowedFundsAllocationPreviousLoanRepaymentAmount,
        SurveyBorrowedFundsAllocationBillsAndLiving,
        SurveyBorrowedFundsAllocationBillsAndLivingAmount,
        SurveyBorrowedFundsAllocationTreatment,
        SurveyBorrowedFundsAllocationTreatmentDetails,
        SurveyShareholdingDebtResponsibility,
        SurveyShareholdingDebtResponsibilityEndDate,
        SurveyLast10YearsBankruptcyApplicationApplicationSubmitted,
        SurveyLast10YearsBankruptcyApplicationCourtDecisionDate,
        SurveyLast10YearsBankruptcyApplicationDebtCancellation,
        SurveyLast10YearsBankruptcyApplicationDebtCancellationAmount,
        SurveyLast10YearsCreditorsDetriment,
        SurveyLast10YearsCreditorsDetrimentDescription,
        SurveyRealEstateOwnership,
        SurveyRealEstateCoOwners,
        SurveyRealEstateDetails,
        SurveyFlatRentIsRenting,
        SurveyFlatRentDetails,
        SurveyHasBankAccounts,
        SurveyAddBankAccounts,
        SurveyHasBankDeposits,
        SurveyAddBankDeposits,
        SurveyHasCash,
        SurveyAddCash,
        SurveyDataConfirmation
    },
    data() {
        return {
            survey: {
                living_alone: false,

                // Inni ewentualni mieszkańcy
                other_residents: {
                    wife: false,
                    husband: false,
                    ex_wife: false,
                    ex_husband: false,
                    partner: false,
                    female_partner: false,
                    father: false,
                    mother: false,
                    grandma: false,
                    grandpa: false,
                    no_of_grandmas: 1,
                    no_of_grandpas: 1,
                    father_in_law: false,
                    mother_in_law: false,
                    son: false,
                    daughter: false,
                    no_of_sons: 1,
                    no_of_daughters: 1,
                    friend: false,

                    female_friend: false,

                    no_of_friends: 1,

                    no_of_female_friends: 1
                },

                //Czy ma dzieci? i ile sztuk jeśli tak
                childs: {
                    has_childs: false,
                    sons: [
                        // {
                        //     legal_age: false,
                        //     is_depend: false,
                        // },
                    ],
                    daughters: [
                        // {
                        //     legal_age: false,
                        //     is_depend: false,
                        // },
                    ]
                },

                // Czy jesteś w związku małżeńskim
                is_married: false,
                property_separation_status: false,
                property_separation_start_date: "",

                // Czy jesteś po rozwodzie
                is_divorced: false,

                // Jeśli prowadzi - od kiedy prowadził;
                // Jeśli zawiesił - od kiedy prowadził, kiedy zawiesił;
                // Jeśli nie prowadzi ale prowadził - kiedy prowadził i kiedy zamknął;
                // Jeśli nie prowadzi i nie prowadził - tyle
                own_company: {
                    // Czy prowadzi działaność gospodarczą
                    // Czy ma działaność gospodarczą
                    has_own_company: false,
                    // Czy jest zawieszona
                    is_suspended: false,
                    start_date: "",
                    // Od kiedy jest zawieszona
                    suspension_date: "",
                    // Czy kiedykolwiek prowadził jdg
                    has_ever_running: false,
                    // Kiedy zamknął - jeżeli prowadził ale nie prowadzi teraz
                    close_date: ""
                },

                // Czy jesteś zatrudniony
                hired: {
                    is_hired: false,
                    company_name: "",
                    company_address: "",
                    company_nip: "",
                    // Stanowisko
                    position: "",
                    // Data rozpoczecia
                    start_date: "",
                    //enum
                    contract_type: "uop",
                    // wynagrodzenie brutto
                    salary: ""
                },

                // Czy pracuje dorywczo
                odd_job: {
                    is_hired: false,
                    income: ""
                },

                // przychody w ostatnich 6  miesiącach
                l6m_income: {
                    has_income: false,
                    items: [
                        // {
                        //     income_source: "", // źródło przychodu
                        //     avg_income: "", // uśredniony przychód z tego okresu
                        //     bailiff_seizure: false, // czy objęte zajęciem komorniczym
                        //     no_of_bailiffs: 0, // liczba komorników zajmujących przychód
                        //     seizure_size: "", // wielkość kwotowa zajęcia
                        //     additional_info: "" // opcjonalne dodatkowe informacje
                        // }
                    ]
                },

                //Czy posiadasz status osoby bezrobotnej?
                unemployed_status: {
                    has_status: false,
                    // Jeżeli tak to od kiedy
                    status_grant_date: ""
                },

                // Czy pandemia miała wpływ i jak?
                pandemic_impact: {
                    affected_employment: false,
                    affected_finances: false,
                    employment_affection_description: "",
                    caused_job_lost: false,
                    caused_salary_reduction: false,
                    caused_salary_loss: false,
                    other_financial_implications: ""
                },

                // Czy jesteś na emetyrurze
                retirement: {
                    is_retired: false,
                    // Jeżeli tak to od kiedy
                    retirement_date: "",
                    // wysokość emerytury
                    amount: ""
                },

                // Czy jesteś na rencie?
                health_pension: {
                    is_pensioner: false,
                    // Powód renty
                    reason: "",
                    // Jeżeli tak to od kiedy
                    obtain_date: "",
                    // wysokość renty
                    amount: ""
                },

                // Czy jesteś osobą niepełnosprawną
                disabled_person: {
                    is_disabled: false,
                    disability_level: "light",
                    // szczegóły niepełnosprawnośći
                    description: "",
                    // Jeżeli tak to od kiedy
                    disability_status_date: ""
                },

                // Czy w ostatnich 12 miesiącach dokonywałeś(aś) czynności prawnych np. sprzedaży lub darowizny, których przedmiotem były ruchomości (np. samochód, sprzęt AGD), wierzytelności lub inne prawa majątkowe, których wartość przekroczyła 10 000 zł?
                l12m_movable_property_transactions: {
                    transactions_made: false,
                    transactions: [
                        // {
                        //     // Rodzaj czynnosci
                        //     //enum ['sell', 'donation']
                        //     transaction_type: "sell",
                        //     // rodzaj (np samochód, sprzęt itp)
                        //     transaction_subject: "",
                        //     // data czynnosci
                        //     transaction_date: "",
                        //     // Kwota
                        //     // RESOLVE: darowizna to 0
                        //     amount: "",
                        //     // Uwagi dodatkowe, opis
                        //     description: ""
                        // }
                    ]
                },

                //Moment zaciągania długów
                // Czy w chwili zaciągania długów miałeś(aś) zatrudnienie?
                incurring_debts_moment: {
                    //Czy w chwili zaciągania długów miałeś(aś) zdolność kredytową?
                    //enum ['yes', 'no', 'dontknow']
                    creditworthiness: "idk",

                    job: false,

                    // Czy w chwili zaciągania długów byłeś(aś) w związku małżeńskim?
                    marriage: false,

                    // Czy w chwili zaciągania długów małżonek/małżonka miał(a) zatrudnienie?
                    spouse_job: false
                },

                // przeznaczenie środków z długów
                // Czy środki z poszczególnych umów pożyczek lub kredytów zostały przeznaczone na spłatę poprzednich pożyczek lub kredytów?
                borrowed_funds_allocation: {
                    previous_loan_repayment: "no",
                    previous_loan_repayment_amount: "",

                    //Czy środki z poszczególnych umów pożyczek lub kredytów zostały przeznaczone na bieżące rachunki oraz na życie?

                    bills_and_living: "no",
                    bills_and_living_amount: "",

                    // Czy środki z poszczególnych umów pożyczek lub kredytów zostały przeznaczone na leczenie?
                    treatment: "no",
                    treatment_monthly_amount: "",
                    treated_diseases: ""
                },

                //Czy byłeś(aś) akcjonariuszem lub komandytariuszem odpowiadającym również za długi spółki?
                shareholding: {
                    debt_responsibility: false,
                    debt_responsibility_end_date: ""
                },

                //Czy w ciągu ostatnich 10 lat składałeś(aś) już wniosek o upadłość?
                l10y_bankruptcy_application: {
                    //Czy juz składał
                    application_submitted: false,
                    //dzień wydania postanowienia sądu
                    court_decision_date: "",
                    //Czy część lub całość zobowiązań została wtedy umorzona?
                    debt_cancellation: false,
                    //Jeśli tak - jaka część % została umożona?
                    debt_cancellation_amount: ""
                },

                //Czy w ciągu 10 lat dokonałeś(aś) czynności skutkującej pokrzywdzeniem wierzycieli np. przekazania nieruchomość bliskiej osobie w celu uniknięcia jej zajęcia przez komornika?
                l10y_creditors_detriment: false,
                l10y_creditors_detriment_description: "",

                //Czy jesteś właścicielem jakiegoś lokalu mieszkalnego?
                real_estate: {
                    //enum [yes, no, shared_ownership]
                    ownership: "no",
                    // Jeśli tak lub jestem współwłaścicielem: wartość
                    property_value: "",
                    // Procent udziału, w przypadku "yes" dajemy  100%
                    ownership_percentage: "",
                    //Jeśli jestem współwłascicleme - kto jest współwłaścicielem?
                    co_owners: ""
                },

                //Czy wynajmujesz jakiś lokal mieszkalny?
                flat_rent: {
                    is_renting: false,
                    // Kwota wynajmu
                    rent_price: "",
                    //Metraż mieszkania
                    flat_size: ""
                },

                has_bank_accounts: false,

                // Czy posiadasz rachunek/rachunki bankowe?
                bank_accounts: [
                    // {
                    //     bank_name: "",
                    //     account_number: ""
                    // }
                ],

                has_bank_deposits: false,

                // Czy posiadasz lokaty w banku
                bank_deposits: [
                    // {
                    //     bank_name: "",
                    //     // kwota na lokacie
                    //     deposit_amount: "",
                    //     //Kod waluty
                    //     currency: ""
                    // }
                ],

                has_cash: false,

                // Czy posiadasz pieniądze w gotówce?
                cash: [
                    // {
                    //     // kwota na lokacie
                    //     cash_amount: "",
                    //     //Kod waluty
                    //     currency: "",
                    //     address: Standardowy obiekt
                    // }
                ]
            },

            survey_questions: [
                {
                    id: "living_alone",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.living_alone === false) {
                            return "other_residents";
                        } else {
                            return "childs.has_childs";
                        }
                    }
                },

                {
                    id: "other_residents",
                    isActive: () => {
                        return this.survey.living_alone === false;
                    },
                    validate: () => {
                        return this.$refs.survey_other_residents.validate();
                    },
                    next: () => {
                        return "childs.has_childs";
                    },
                    prev: () => {
                        return "living_alone";
                    }
                },

                {
                    id: "childs.has_childs",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.childs.has_childs === true) {
                            return "childs.count";
                        } else {
                            return "is_married";
                        }
                    },
                    prev: () => {
                        if (this.survey.living_alone === false) {
                            return "other_residents";
                        } else {
                            return "living_alone";
                        }
                    }
                },

                {
                    id: "childs.count",
                    isActive: () => {
                        return this.survey.childs.has_childs === true;
                    },
                    validate: () => {
                        return this.$refs.survey_childs_count.validate();
                    },
                    next: () => {
                        if (
                            this.survey.childs.sons.length > 0 ||
                            this.survey.childs.daughters.length > 0
                        ) {
                            return "childs.dependencies";
                        } else {
                            return "is_married";
                        }
                    },
                    prev: () => {
                        return "childs.has_childs";
                    }
                },

                {
                    id: "childs.dependencies",
                    isActive: () => {
                        return (
                            (this.survey.childs.sons.length > 0 ||
                                this.survey.childs.daughters.length > 0) &&
                            this.survey.childs.has_childs === true
                        );
                    },
                    validate: () => {
                        return this.$refs.survey_childs_dependencies.validate();
                    },
                    next: () => {
                        return "is_married";
                    },
                    prev: () => {
                        return "childs.count";
                    }
                },

                {
                    id: "is_married",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.is_married === false) {
                            return "is_divorced";
                        } else {
                            return "property_separation_status";
                        }
                    },
                    prev: () => {
                        if (
                            this.survey.childs.has_childs === true &&
                            (this.survey.childs.sons.length > 0 ||
                                this.survey.childs.daughters.length > 0)
                        ) {
                            return "childs.dependencies";
                        } else {
                            return "childs.has_childs";
                        }
                    }
                },

                {
                    id: "property_separation_status",
                    isActive: () => {
                        return this.survey.is_married !== false;
                    },
                    next: () => {
                        if (this.survey.property_separation_status === true) {
                            return "property_separation_start_date";
                        } else {
                            return "own_company.has_own_company";
                        }
                    },
                    prev: () => {
                        return "is_married";
                    }
                },

                {
                    id: "property_separation_start_date",
                    isActive: () => {
                        return this.survey.property_separation_status !== false;
                    },
                    validate: () => {
                        return this.$refs.property_separation_start_date.validate();
                    },
                    next: () => {
                        return "own_company.has_own_company";
                    },
                    prev: () => {
                        return "property_separation_status";
                    }
                },

                {
                    id: "is_divorced",
                    isActive: () => {
                        return this.survey.is_married === false;
                    },
                    next: () => {
                        return "own_company.has_own_company";
                    },
                    prev: () => {
                        return "is_married";
                    }
                },

                {
                    id: "own_company.has_own_company",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.own_company.has_own_company === true) {
                            return "own_company.is_suspended";
                        } else {
                            return "own_company.has_ever_running";
                        }
                    },
                    prev: () => {
                        if (this.survey.is_married === false) {
                            return "is_divorced";
                        } else {
                            if (this.survey.property_separation_status === true) {
                                return "property_separation_start_date";
                            }
                            return "property_separation_status";
                        }
                    }
                },

                {
                    id: "own_company.is_suspended",
                    isActive: () => {
                        return this.survey.own_company.has_own_company === true;
                    },
                    next: () => {
                        if (this.survey.own_company.is_suspended === true) {
                            return "own_company.suspension_date";
                        } else if (this.survey.own_company.is_suspended === false) {
                            return "own_company.start_date";
                        } else {
                            return "hired.is_hired";
                        }
                    },
                    prev: () => {
                        return "own_company.has_own_company";
                    }
                },

                {
                    id: "own_company.start_date",
                    isActive: () => {
                        return (
                            this.survey.own_company.has_own_company === true &&
                            this.survey.own_company.is_suspended === false
                        );
                    },
                    validate: () => {
                        return this.$refs.survey_start_date.validate();
                    },
                    next: () => {
                        return "hired.is_hired";
                    },
                    prev: () => {
                        return "own_company.is_suspended";
                    }
                },

                {
                    id: "own_company.has_ever_running",
                    isActive: () => {
                        return this.survey.own_company.has_own_company === false;
                    },
                    next: () => {
                        if (this.survey.own_company.has_ever_running === true) {
                            return "own_company.close_date";
                        } else {
                            return "hired.is_hired";
                        }
                    },
                    prev: () => {
                        return "own_company.has_own_company";
                    }
                },

                {
                    id: "own_company.suspension_date",
                    isActive: () => {
                        return (
                            this.survey.own_company.has_own_company === true &&
                            this.survey.own_company.is_suspended === true
                        );
                    },
                    validate: () => {
                        return this.$refs.survey_suspension_date.validate();
                    },
                    next: () => {
                        return "hired.is_hired";
                    },
                    prev: () => {
                        return "own_company.is_suspended";
                    }
                },

                {
                    id: "own_company.close_date",
                    isActive: () => {
                        return (
                            this.survey.own_company.has_own_company === false &&
                            this.survey.own_company.has_ever_running === true
                        );
                    },
                    validate: () => {
                        return this.$refs.survey_close_date.validate();
                    },
                    next: () => {
                        return "hired.is_hired";
                    },
                    prev: () => {
                        return "own_company.has_ever_running";
                    }
                },

                {
                    id: "hired.is_hired",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.hired.is_hired === true) {
                            return "hired.employment_place";
                        } else {
                            return "odd_job";
                        }
                    },
                    prev: () => {
                        if (this.survey.own_company.is_suspended === true) {
                            return "own_company.suspension_date";
                        } else if (
                            this.survey.own_company.has_ever_running === false &&
                            this.survey.own_company.has_own_company === false
                        ) {
                            return "own_company.has_ever_running";
                        } else if (
                            this.survey.own_company.has_own_company === false &&
                            this.survey.own_company.has_ever_running === true
                        ) {
                            return "own_company.close_date";
                        } else if (
                            this.survey.own_company.has_own_company === true &&
                            this.survey.own_company.is_suspended === false
                        )
                            return "own_company.is_suspended";
                        else if (this.survey.own_company.has_ever_running === true) {
                            return "own_company.close_data";
                        }
                    }
                },

                {
                    id: "hired.employment_place",
                    isActive: () => {
                        return this.survey.hired.is_hired === true;
                    },
                    validate: () => {
                        return this.$refs.hired_employment_place.validate();
                    },
                    next: () => {
                        return "odd_job";
                    },
                    prev: () => {
                        return "hired.is_hired";
                    }
                },

                {
                    id: "odd_job",
                    isActive: () => {
                        return true;
                    },
                    validate: () => {
                        return this.$refs.odd_job.validate();
                    },
                    next: () => {
                        return "l6m_income.has_income";
                    },
                    prev: () => {
                        if (this.survey.hired.is_hired === true) {
                            return "hired.employment_place";
                        }
                        return "hired.is_hired";
                    }
                },

                {
                    id: "l6m_income.has_income",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.l6m_income.has_income === true) {
                            return "add_income_sources";
                        } else {
                            return "unemployed_status.has_status";
                        }
                    },
                    prev: () => {
                        return "odd_job";
                    }
                },

                {
                    id: "add_income_sources",
                    isActive: () => {
                        return this.survey.l6m_income.has_income === true;
                    },
                    validate: () => {
                        return this.$refs.add_income_sources.validate();
                    },
                    next: () => {
                        return "unemployed_status.has_status";
                    },
                    prev: () => {
                        return "l6m_income.has_income";
                    }
                },

                {
                    id: "unemployed_status.has_status",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.unemployed_status.has_status === true) {
                            return "unemployed_status.status_grant_date";
                        } else {
                            return "pandemic_impact.affected_employment";
                        }
                    },
                    prev: () => {
                        if (this.survey.l6m_income.has_income === true) {
                            return "add_income_sources";
                        } else {
                            return "l6m_income.has_income";
                        }
                    }
                },

                {
                    id: "unemployed_status.status_grant_date",
                    isActive: () => {
                        return this.survey.unemployed_status.has_status === true;
                    },
                    validate: () => {
                        return this.$refs.status_grant_date.validate();
                    },
                    next: () => {
                        return "pandemic_impact.affected_employment";
                    },
                    prev: () => {
                        return "unemployed_status.has_status";
                    }
                },

                {
                    id: "pandemic_impact.affected_employment",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.pandemic_impact.affected_employment === true) {
                            return "pandemic_impact.employment_affection.description";
                        } else {
                            return "retirement.is_retired";
                        }
                    },
                    prev: () => {
                        if (this.survey.unemployed_status.has_status === true) {
                            return "unemployed_status.status_grant_date";
                        } else {
                            return "unemployed_status.has_status";
                        }
                    }
                },

                {
                    id: "pandemic_impact.employment_affection.description",
                    isActive: () => {
                        return this.survey.pandemic_impact.affected_employment === true;
                    },
                    validate: () => {
                        return this.$refs.survey_pandemic_employment_description.validate();
                    },
                    next: () => {
                        return "retirement.is_retired";
                    },
                    prev: () => {
                        return "pandemic_impact.affected_employment";
                    }
                },

                {
                    id: "retirement.is_retired",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.retirement.is_retired === true) {
                            return "retirement.details";
                        } else {
                            return "health_pension.is_pensioner";
                        }
                    },
                    prev: () => {
                        if (this.survey.pandemic_impact.affected_employment === true) {
                            return "pandemic_impact.employment_affection.description";
                        } else {
                            return "pandemic_impact.affected_employment";
                        }
                    }
                },

                {
                    id: "retirement.details",
                    isActive: () => {
                        return this.survey.retirement.is_retired === true;
                    },
                    validate: () => {
                        return this.$refs.retirement_details.validate();
                    },
                    next: () => {
                        return "health_pension.is_pensioner";
                    },
                    prev: () => {
                        return "retirement.is_retired";
                    }
                },

                {
                    id: "health_pension.is_pensioner",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.health_pension.is_pensioner === true) {
                            return "health_pension.details";
                        } else {
                            return "disabled_person.is_disabled";
                        }
                    },
                    prev: () => {
                        if (this.survey.retirement.is_retired === true) {
                            return "retirement.details";
                        } else {
                            return "retirement.is_retired";
                        }
                    }
                },

                {
                    id: "health_pension.details",
                    isActive: () => {
                        return this.survey.health_pension.is_pensioner === true;
                    },
                    validate: () => {
                        return this.$refs.health_pension_details.validate();
                    },
                    next: () => {
                        return "disabled_person.is_disabled";
                    },
                    prev: () => {
                        return "health_pension.is_pensioner";
                    }
                },

                {
                    id: "disabled_person.is_disabled",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.disabled_person.is_disabled === true) {
                            return "disabled_person.details";
                        } else {
                            return "pandemic_impact.affected_finances";
                        }
                    },
                    prev: () => {
                        if (this.survey.health_pension.is_pensioner === true) {
                            return "health_pension.details";
                        } else {
                            return "health_pension.is_pensioner";
                        }
                    }
                },

                {
                    id: "disabled_person.details",
                    isActive: () => {
                        return this.survey.disabled_person.is_disabled === true;
                    },
                    validate: () => {
                        return this.$refs.disability_details.validate();
                    },
                    next: () => {
                        return "pandemic_impact.affected_finances";
                    },
                    prev: () => {
                        return "disabled_person.is_disabled";
                    }
                },

                {
                    id: "pandemic_impact.affected_finances",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.pandemic_impact.affected_finances === true) {
                            return "pandemic_impact.finances_affection_details";
                        } else {
                            return "l12m_movable_property_transactions.transactions_made";
                        }
                    },
                    prev: () => {
                        if (this.survey.disabled_person.is_disabled === true) {
                            return "disabled_person.details";
                        } else {
                            return "disabled_person.is_disabled";
                        }
                    }
                },

                {
                    id: "pandemic_impact.finances_affection_details",
                    isActive: () => {
                        return this.survey.pandemic_impact.affected_finances === true;
                    },
                    validate: () => {
                        return this.$refs.finances_affection_details.validate();
                    },
                    next: () => {
                        return "l12m_movable_property_transactions.transactions_made";
                    },
                    prev: () => {
                        return "pandemic_impact.affected_finances";
                    }
                },

                {
                    id: "l12m_movable_property_transactions.transactions_made",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (
                            this.survey.l12m_movable_property_transactions.transactions_made ===
                            true
                        ) {
                            return "add_transactions_on_movables";
                        } else {
                            return "incurring_debts_moment.creditworthiness";
                        }
                    },
                    prev: () => {
                        if (this.survey.pandemic_impact.affected_finances === true) {
                            return "pandemic_impact.finances_affection_details";
                        } else {
                            return "pandemic_impact.affected_finances";
                        }
                    }
                },

                {
                    id: "add_transactions_on_movables",
                    isActive: () => {
                        return (
                            this.survey.l12m_movable_property_transactions.transactions_made ===
                            true
                        );
                    },
                    validate: () => {
                        return this.$refs.add_transactions_on_movables.validate();
                    },
                    next: () => {
                        return "incurring_debts_moment.creditworthiness";
                    },
                    prev: () => {
                        return "l12m_movable_property_transactions.transactions_made";
                    }
                },

                {
                    id: "incurring_debts_moment.creditworthiness",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        return "incurring_debts_moment.job";
                    },
                    prev: () => {
                        if (
                            this.survey.l12m_movable_property_transactions.transactions_made ===
                            true
                        ) {
                            return "add_transactions_on_movables";
                        } else {
                            return "l12m_movable_property_transactions.transactions_made";
                        }
                    }
                },

                {
                    id: "incurring_debts_moment.job",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        return "incurring_debts_moment.marriage";
                    },
                    prev: () => {
                        return "incurring_debts_moment.creditworthiness";
                    }
                },

                {
                    id: "incurring_debts_moment.marriage",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.incurring_debts_moment.marriage === true) {
                            return "incurring_debts_moment.spouse_job";
                        } else {
                            return "borrowed_funds_allocation.previous_loan_repayment";
                        }
                    },
                    prev: () => {
                        return "incurring_debts_moment.job";
                    }
                },

                {
                    id: "incurring_debts_moment.spouse_job",
                    isActive: () => {
                        return this.survey.incurring_debts_moment.marriage === true;
                    },
                    next: () => {
                        return "borrowed_funds_allocation.previous_loan_repayment";
                    },
                    prev: () => {
                        return "incurring_debts_moment.marriage";
                    }
                },

                {
                    id: "borrowed_funds_allocation.previous_loan_repayment",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (
                            this.survey.borrowed_funds_allocation.previous_loan_repayment !== "no"
                        ) {
                            return "borrowed_funds_allocation.previous_loan_repayment_amount";
                        } else {
                            return "borrowed_funds_allocation.bills_and_living";
                        }
                    },
                    prev: () => {
                        if (this.survey.incurring_debts_moment.marriage === true) {
                            return "incurring_debts_moment.spouse_job";
                        } else {
                            return "incurring_debts_moment.marriage";
                        }
                    }
                },

                {
                    id: "borrowed_funds_allocation.previous_loan_repayment_amount",
                    isActive: () => {
                        return (
                            this.survey.borrowed_funds_allocation.previous_loan_repayment !== "no"
                        );
                    },
                    validate: () => {
                        return this.$refs.previous_loan_repayment_amount.validate();
                    },
                    next: () => {
                        return "borrowed_funds_allocation.bills_and_living";
                    },
                    prev: () => {
                        return "borrowed_funds_allocation.previous_loan_repayment";
                    }
                },

                {
                    id: "borrowed_funds_allocation.bills_and_living",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.borrowed_funds_allocation.bills_and_living !== "no") {
                            return "borrowed_funds_allocation.bills_and_living_amount";
                        } else {
                            return "borrowed_funds_allocation.treatment";
                        }
                    },
                    prev: () => {
                        if (
                            this.survey.borrowed_funds_allocation.previous_loan_repayment !== "no"
                        ) {
                            return "borrowed_funds_allocation.previous_loan_repayment_amount";
                        } else {
                            return "borrowed_funds_allocation.previous_loan_repayment";
                        }
                    }
                },

                {
                    id: "borrowed_funds_allocation.bills_and_living_amount",
                    isActive: () => {
                        return this.survey.borrowed_funds_allocation.bills_and_living !== "no";
                    },
                    validate: () => {
                        return this.$refs.bills_and_living_amount.validate();
                    },
                    next: () => {
                        return "borrowed_funds_allocation.treatment";
                    },
                    prev: () => {
                        return "borrowed_funds_allocation.bills_and_living";
                    }
                },

                {
                    id: "borrowed_funds_allocation.treatment",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.borrowed_funds_allocation.treatment !== "no") {
                            return "borrowed_funds_allocation.treatment_details";
                        } else {
                            return "shareholding.debt_responsibility";
                        }
                    },
                    prev: () => {
                        if (this.survey.borrowed_funds_allocation.bills_and_living !== "no") {
                            return "borrowed_funds_allocation.bills_and_living_amount";
                        } else {
                            return "borrowed_funds_allocation.bills_and_living";
                        }
                    }
                },

                {
                    id: "borrowed_funds_allocation.treatment_details",
                    isActive: () => {
                        return this.survey.borrowed_funds_allocation.treatment !== "no";
                    },
                    validate: () => {
                        return this.$refs.treatment_details.validate();
                    },
                    next: () => {
                        return "shareholding.debt_responsibility";
                    },
                    prev: () => {
                        return "borrowed_funds_allocation.treatment";
                    }
                },

                {
                    id: "shareholding.debt_responsibility",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.shareholding.debt_responsibility === true) {
                            return "shareholding.debt_responsibility_end_date";
                        } else {
                            return "l10y_bankruptcy_application.application_submitted";
                        }
                    },
                    prev: () => {
                        if (this.survey.borrowed_funds_allocation.treatment !== "no") {
                            return "borrowed_funds_allocation.treatment_details";
                        } else {
                            return "borrowed_funds_allocation.treatment";
                        }
                    }
                },

                {
                    id: "shareholding.debt_responsibility_end_date",
                    isActive: () => {
                        return this.survey.shareholding.debt_responsibility === true;
                    },
                    validate: () => {
                        return this.$refs.debt_responsibility_end_date.validate();
                    },
                    next: () => {
                        return "l10y_bankruptcy_application.application_submitted";
                    },
                    prev: () => {
                        return "shareholding.debt_responsibility";
                    }
                },

                {
                    id: "l10y_bankruptcy_application.application_submitted",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (
                            this.survey.l10y_bankruptcy_application.application_submitted === true
                        ) {
                            return "l10y_bankruptcy_application.court_decision_date";
                        } else {
                            return "l10y_creditors_detriment";
                        }
                    },
                    prev: () => {
                        if (this.survey.shareholding.debt_responsibility === true) {
                            return "shareholding.debt_responsibility_end_date";
                        } else {
                            return "shareholding.debt_responsibility";
                        }
                    }
                },

                {
                    id: "l10y_bankruptcy_application.court_decision_date",
                    isActive: () => {
                        return (
                            this.survey.l10y_bankruptcy_application.application_submitted === true
                        );
                    },
                    validate: () => {
                        return this.$refs.court_decision_date.validate();
                    },
                    next: () => {
                        return "l10y_bankruptcy_application.debt_cancellation";
                    },
                    prev: () => {
                        return "l10y_bankruptcy_application.application_submitted";
                    }
                },

                {
                    id: "l10y_bankruptcy_application.debt_cancellation",
                    isActive: () => {
                        return (
                            this.survey.l10y_bankruptcy_application.application_submitted === true
                        );
                    },
                    next: () => {
                        if (this.survey.l10y_bankruptcy_application.debt_cancellation === true) {
                            return "l10y_bankruptcy_application.debt_cancellation_amount";
                        } else {
                            return "l10y_creditors_detriment";
                        }
                    },
                    prev: () => {
                        return "l10y_bankruptcy_application.court_decision_date";
                    }
                },

                {
                    id: "l10y_bankruptcy_application.debt_cancellation_amount",
                    isActive: () => {
                        return this.survey.l10y_bankruptcy_application.debt_cancellation === true;
                    },
                    validate: () => {
                        return this.$refs.debt_cancellation_amount.validate();
                    },
                    next: () => {
                        return "l10y_creditors_detriment";
                    },
                    prev: () => {
                        return "l10y_bankruptcy_application.debt_cancellation";
                    }
                },

                {
                    id: "l10y_creditors_detriment",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.l10y_creditors_detriment === true) {
                            return "l10y_creditors_detriment_description";
                        } else {
                            return "real_estate.ownership";
                        }
                    },
                    prev: () => {
                        if (
                            this.survey.l10y_bankruptcy_application.application_submitted ===
                                true &&
                            this.survey.l10y_bankruptcy_application.debt_cancellation === true
                        ) {
                            return "l10y_bankruptcy_application.debt_cancellation_amount";
                        } else if (
                            this.survey.l10y_bankruptcy_application.application_submitted ===
                                true &&
                            this.survey.l10y_bankruptcy_application.debt_cancellation === false
                        ) {
                            return "l10y_bankruptcy_application.debt_cancellation";
                        } else {
                            return "l10y_bankruptcy_application.application_submitted";
                        }
                    }
                },

                {
                    id: "l10y_creditors_detriment_description",
                    isActive: () => {
                        return this.survey.l10y_creditors_detriment === true;
                    },
                    validate: () => {
                        return this.$refs.detriment_description.validate();
                    },
                    next: () => {
                        return "real_estate.ownership";
                    },
                    prev: () => {
                        return "l10y_creditors_detriment";
                    }
                },

                {
                    id: "real_estate.ownership",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.real_estate.ownership === "yes") {
                            return "real_estate.details";
                        } else if (this.survey.real_estate.ownership === "no") {
                            return "flat_rent.is_renting";
                        } else {
                            return "real_estate.co_owners";
                        }
                    },
                    prev: () => {
                        if (this.survey.l10y_creditors_detriment === true) {
                            return "l10y_creditors_detriment_description";
                        } else {
                            return "l10y_creditors_detriment";
                        }
                    }
                },

                {
                    id: "real_estate.co_owners",
                    isActive: () => {
                        return this.survey.real_estate.ownership === "shared_ownership";
                    },
                    validate: () => {
                        return this.$refs.real_estate_co_owners.validate();
                    },
                    next: () => {
                        return "real_estate.details";
                    },
                    prev: () => {
                        return "real_estate.ownership";
                    }
                },

                {
                    id: "real_estate.details",
                    isActive: () => {
                        return (
                            this.survey.real_estate.ownership === "shared_ownership" ||
                            this.survey.real_estate.ownership === "yes"
                        );
                    },
                    validate: () => {
                        return this.$refs.real_estate_details.validate();
                    },
                    next: () => {
                        return "flat_rent.is_renting";
                    },
                    prev: () => {
                        if (this.survey.real_estate.ownership === "shared_ownership") {
                            return "real_estate.co_owners";
                        } else {
                            return "real_estate.ownership";
                        }
                    }
                },

                {
                    id: "flat_rent.is_renting",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.flat_rent.is_renting === true) {
                            return "flat_rent.details";
                        } else {
                            return "has_bank_accounts";
                        }
                    },
                    prev: () => {
                        if (
                            this.survey.real_estate.ownership === "shared_ownership" ||
                            this.survey.real_estate.ownership === "yes"
                        ) {
                            return "real_estate.details";
                        } else {
                            return "real_estate.ownership";
                        }
                    }
                },

                {
                    id: "flat_rent.details",
                    isActive: () => {
                        return this.survey.flat_rent.is_renting === true;
                    },
                    validate: () => {
                        return this.$refs.flat_rent_details.validate();
                    },
                    next: () => {
                        return "has_bank_accounts";
                    },
                    prev: () => {
                        return "flat_rent.is_renting";
                    }
                },

                {
                    id: "has_bank_accounts",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.has_bank_accounts === true) {
                            return "add_bank_accounts";
                        } else {
                            return "has_bank_deposits";
                        }
                    },
                    prev: () => {
                        if (this.survey.flat_rent.is_renting === true) {
                            return "flat_rent.details";
                        } else {
                            return "flat_rent.is_renting";
                        }
                    }
                },

                {
                    id: "add_bank_accounts",
                    isActive: () => {
                        return this.survey.has_bank_accounts === true;
                    },
                    validate: () => {
                        return this.$refs.add_bank_accounts.validate();
                    },
                    next: () => {
                        return "has_bank_deposits";
                    },
                    prev: () => {
                        return "has_bank_accounts";
                    }
                },

                {
                    id: "has_bank_deposits",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.has_bank_deposits === true) {
                            return "add_bank_deposits";
                        } else {
                            return "has_cash";
                        }
                    },
                    prev: () => {
                        if (this.survey.has_bank_accounts === true) {
                            return "add_bank_accounts";
                        } else {
                            return "has_bank_accounts";
                        }
                    }
                },

                {
                    id: "add_bank_deposits",
                    isActive: () => {
                        return this.survey.has_bank_deposits === true;
                    },
                    validate: () => {
                        return this.$refs.add_bank_deposits.validate();
                    },
                    next: () => {
                        return "has_cash";
                    },
                    prev: () => {
                        return "has_bank_deposits";
                    }
                },

                {
                    id: "has_cash",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        if (this.survey.has_cash === true) {
                            return "add_cash";
                        } else {
                            return "survey_data_confirmation";
                        }
                    },
                    prev: () => {
                        if (this.survey.has_bank_deposits === true) {
                            return "add_bank_deposits";
                        } else {
                            return "has_bank_deposits";
                        }
                    }
                },

                {
                    id: "add_cash",
                    isActive: () => {
                        return this.survey.has_cash === true;
                    },
                    validate: () => {
                        return this.$refs.add_cash.validate();
                    },
                    next: () => {
                        return "survey_data_confirmation";
                    },
                    prev: () => {
                        return "has_cash";
                    }
                },

                {
                    id: "survey_data_confirmation",
                    isActive: () => {
                        return true;
                    },
                    next: () => {
                        return;
                    },
                    prev: () => {
                        if (this.survey.has_cash === true) {
                            return "add_cash";
                        } else {
                            return "has_cash";
                        }
                    }
                }
            ],

            active_question_id: "living_alone",

            all_data_mounted: false
        };
    },
    computed: {
        app() {
            return this.$store.getters["application/getApplication"];
        },
        question_current_no() {
            return (
                this.survey_questions
                    .filter(question => question.isActive())
                    .findIndex(question => question.id === this.active_question_id) + 1
            );
        },
        questions_pool() {
            return this.survey_questions.filter(question => question.isActive()).length;
        },
        active_question() {
            return this.survey_questions.find(question => question.id === this.active_question_id);
        }
    },

    watch: {
        survey: {
            deep: true,
            handler() {
                this.cacheSurvey();
            }
        },
        active_question_id() {
            this.cacheSurveyActiveQuestion();
            this.handleInStepHintVisibility();
        }
    },

    methods: {
        cacheSurvey() {
            saveInLocalStorage(`app_${this.app._id}_survey`, JSON.stringify(this.survey));
        },
        cacheSurveyActiveQuestion() {
            this.$store.state.survey_active_question_id = this.active_question_id;
            saveInLocalStorage(
                `app_${this.app._id}_survey_active_question`,
                this.active_question_id
            );
        },

        handleInStepHintVisibility() {
            if (this.active_question_id == "l10y_creditors_detriment") {
                this.$store.commit("setForceHideHint", false);
            } else {
                this.$store.commit("setForceHideHint", true);
            }
        },

        async nextQuestion() {
            if (
                this.active_question.validate !== undefined &&
                typeof this.active_question.validate === "function"
            ) {
                this.active_question.validate();
                // const result =

                // if (result !== true) {
                //     this.$message({
                //         type: "error",
                //         msg: typeof result === "string" ? result : "Wystąpił błąd"
                //     });
                //     return false;
                // }
            }
            await this.$refs.wrapper.scrollToTop();
            this.active_question_id = this.active_question.next();
        },

        async prevQuestion() {
            await this.$refs.wrapper.scrollToTop();
            this.active_question_id = this.active_question.prev();
        },

        sendSurveyData() {
            this.$refs.survey_data_confirmation.openConfirmModal();
        }
    },

    created() {
        this.$store.commit("setForceHideHint", true);
    },
    mounted() {
        if (!this.$store.state.initial_layout_set) return;
        const s = retrieveFromLocalStorage(`app_${this.app._id}_survey`);

        if (s) {
            try {
                const parsed = JSON.parse(s);
                this.survey = parsed;

                const step = retrieveFromLocalStorage(`app_${this.app._id}_survey_active_question`);
                if (step) {
                    this.active_question_id = step;
                    this.$store.state.survey_active_question_id = step;
                    this.handleInStepHintVisibility();
                }
            } catch (err) {
                console.error(err);
            }
        }

        const sv = retrieveFromLocalStorage("survey_validations");

        if (sv) {
            try {
                const parsed = JSON.parse(sv);

                this.$store.commit("formData/setSurveyValidation", parsed);
            } catch (err) {
                console.error(err);
            }
        }

        this.all_data_mounted = true;
    },

    metaInfo() {
        return {
            title: `Ankieta - krok ${this.question_current_no} z ${this.questions_pool}`
        };
    }
};
