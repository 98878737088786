//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "../mixins";
import PpRadioButton from "@/components/PpRadioButton.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpRadioButton
    }
};
