//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        label: {
            type: String
        },
        title: {
            type: String
        },
        data: {
            type: Object
        },
        disabled: {
            type: Boolean
        },
        err: {
            type: Boolean
        },
        errMsg: {
            type: String
        }
    },
    data() {
        return {
            editable: true
        };
    },

    methods: {
        saveData() {
            this.$emit("submit");
        },

        onCancel() {
            this.$emit("cancel");
            this.closeModal();
        },

        editData() {
            this.editable = !this.editable;
        },

        closeModal() {
            this.editable = true;
            this.$emit("close");
        }
    }
};
