//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import currencies from "../../static/currencies.json";
import { updateSurveyData } from "../mixins";
import GeneralAddressFormElement from "@/components/GeneralAddressFormElement";

export default {
    mixins: [updateSurveyData],

    components: {
        GeneralAddressFormElement
    },

    props: {
        label: {
            type: String
        },
        value: {
            type: Object
        }
    },

    data() {
        return {
            local_data: {
                cash_amount: "",
                currency: "PLN",
                address: null
            },
            currency_select_search: ""
        };
    },

    computed: {
        currencySelectItems() {
            const c = currencies
                .map(currency => ({
                    text: `${currency.code} (${currency.name.pl})`,
                    value: currency.code
                }))
                .sort((a, b) => {
                    if (a.text > b.text) return 1;
                    else if (b.text > a.text) return -1;
                    else return 0;
                });
            if (this.currency_select_search != "") {
                return c.filter(currency =>
                    currency.text.toLowerCase().includes(this.currency_select_search.toLowerCase())
                );
            }
            this.emitUpdate();
            return c;
        }
    },

    watch: {
        value() {
            this.handleValue();
        }
    },

    methods: {
        saveData() {
            return this.local_data;
        },
        handleValue() {
            if (this.value) {
                this.local_data = { ...this.value };
            }
        },
        validate() {
            const validations = [
                this.$refs.cash_amount.validate(),
                this.$refs.address_form.validate()
            ];

            if (validations.includes(false)) {
                return false;
            }

            return true;
        }
    },

    mounted() {
        this.handleValue();
    }
};
