//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "@/components/mixins";
import PpRadioButton from "@/components/PpRadioButton.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpRadioButton
    },

    methods: {
        validate() {
            const a = [];
            let result = true;

            if (this.survey.odd_job.is_hired !== false) {
                a.push(this.$refs.income.validate());
            }

            if (a.indexOf(false) !== -1) {
                result = "Proszę podać osiągany dochód";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "odd_job",
                value: result
            });

            return result;
        }
    }
};
