//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpRadioButton from "@/components/PpRadioButton.vue";
import PpComboDateInput from "../PpComboDateInput.vue";

export default {
    components: {
        PpRadioButton,
        PpComboDateInput
    },

    props: {
        label: {
            type: String
        },
        value: {
            type: Object
        }
    },

    data() {
        return {
            local_data: {
                amount: "",
                description: "",
                transaction_date: "",
                transaction_subject: "",
                transaction_type: "sell"
            }
        };
    },

    watch: {
        value() {
            this.handleValue();
        }
    },

    methods: {
        saveData() {
            return this.local_data;
        },
        handleValue() {
            if (this.value) {
                this.local_data = { ...this.value };
            }
        },
        validate() {
            const validations = [
                this.$refs.transaction_subject.validate(),
                this.$refs.transaction_amount.validate(),
                this.$refs.transaction_date.validate(),
                this.$refs.transaction_description.validate()
            ];

            if (validations.includes(false)) {
                return false;
            }

            return true;
        }
    },

    mounted() {
        this.handleValue();
    }
};
