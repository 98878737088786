var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"udfe"},[_c('WkTextField',{ref:"bills_and_living_amount",attrs:{"label":"Kwota","placeholder":"Kwota brutto","show-asterisk":"","rules":[
            function (v) {
                if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                if (v.length > 31) { return 'Maksymalna długość kwoty to 31 znaków'; }
                if (!this$1.$store.state.REGEX_IS_MONEY.test(v))
                    { return 'Kwota musi zawierać liczbę'; }
                return true;
            }
        ]},on:{"input":_vm.emitUpdate,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('submit')}},model:{value:(_vm.survey.borrowed_funds_allocation.bills_and_living_amount),callback:function ($$v) {_vm.$set(_vm.survey.borrowed_funds_allocation, "bills_and_living_amount", $$v)},expression:"survey.borrowed_funds_allocation.bills_and_living_amount"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }