//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "../mixins";
import PpDataField from "../PpDataField.vue";
import SurveyIncomeSourcesFormElement from "./SurveyIncomeSourcesFormElement.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpDataField,
        SurveyIncomeSourcesFormElement
    },

    data() {
        return {
            is_display_modal: false
        };
    },

    methods: {
        openModal() {
            this.is_display_modal = true;
        },

        closeModal() {
            this.is_display_modal = false;
        },

        addIncomeSource() {
            if (this.$refs.isfe.validate() === true) {
                this.survey.l6m_income.items.push(this.$refs.isfe.saveData());
                this.emitUpdate();
                this.closeModal();
            }
        },

        deleteSource(index) {
            this.survey.l6m_income.items.splice(index, 1);
            this.emitUpdate();
        },

        getSourceInfo(source_details) {
            const bailiffs_variants = ["", "1 komornik", "{{n}} komorników", "{{n}} komorników"];

            return `średni przychód: ${source_details.avg_income} ${
                source_details.bailiff_seizure
                    ? `•  ${this.$store.getters["pluralize"]({
                          values: bailiffs_variants,
                          n: source_details.no_of_bailiffs
                      })}  • łączna kwota zajęcia: ${source_details.seizure_size}`
                    : "• brak zajęcia"
            }`;
        },

        validate() {
            let result = true;

            if (this.survey.l6m_income.items.length === 0) {
                result = "Dodaj co najmniej jedno źródło przychodu";
            }
            if (this.survey.l6m_income.items.length > 127) {
                result = "Możesz dodać maksymalnie 127 źródeł przychodu";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "l6m_income_items",
                value: result
            });

            return result;
        }
    }
};
