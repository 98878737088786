//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "../mixins";
import PpDataField from "../PpDataField.vue";
import SurveyBankAccountFormElement from "./SurveyBankAccountFormElement.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpDataField,
        SurveyBankAccountFormElement
    },

    data() {
        return {
            is_display_modal: false
        };
    },

    methods: {
        openModal() {
            this.is_display_modal = true;
        },
        closeModal() {
            this.is_display_modal = false;
        },

        addIncomeAccount() {
            if (this.$refs.bafe.validate() === true) {
                this.survey.bank_accounts.push(this.$refs.bafe.saveData());
                this.emitUpdate();
                this.closeModal();
            }
        },

        deleteAccount(index) {
            this.survey.bank_accounts.splice(index, 1);
            this.emitUpdate();
        },

        getAccountInfo(account_details) {
            return account_details.account_number;
            // return `${account_details.funds_amount} • ${account_details.currency}`;
        },

        validate() {
            let result = true;

            if (this.survey.bank_accounts.length === 0) {
                result = "Dodaj co najmniej jedno konto bankowe";
            }
            if (this.survey.bank_accounts.length > 127) {
                result = "Możesz dodać maksymalnie 127 kont bankowych";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "bank_accounts",
                value: result
            });

            return result;
        }
    }
};
