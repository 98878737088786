//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "@/components/mixins";
import PpComboDateInput from "@/components//PpComboDateInput.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpComboDateInput
    },

    props: {
        singleColumn: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            disabled_person_since_born: false
        };
    },

    methods: {
        validate() {
            let result = true;

            const validations = [
                this.$refs.disability_status_date.validate(),
                this.$refs.disability_description.validate()
            ];

            if (validations.includes(false)) {
                result = "Proszę poprawić błędy w formularzu";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "disabled_person_details",
                value: result
            });

            return result;
        }
    }
};
