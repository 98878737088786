//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "../mixins";
import PpRadioButton from "@/components/PpRadioButton.vue";
import Alert from "@/components/Alert.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpRadioButton,
        Alert
    },

    methods: {
        handleHasOwnCompany() {
            if (this.survey.own_company.has_own_company === false) {
                this.survey.own_company.is_suspended = false;
            }

            this.emitUpdate();
        }
    }
};
