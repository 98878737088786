//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "@/components/mixins";

export default {
    mixins: [updateSurveyData],

    methods: {
        validate() {
            let result = true;

            const copy = { ...this.survey.pandemic_impact };

            const arr = [
                "affected_employment",
                "affected_finances",
                "employment_affection_description",
                "other_financial_implications"
            ];

            for (const exp of arr) {
                delete copy[exp];
            }
            if (this.survey.pandemic_impact.other_financial_implications === "") {
                if (!Object.values(copy).includes(true))
                    result = "Proszę wybrać conajmniej jedną opcję";
            }
            const validations = [this.$refs.other_financial_implications.validate()];
            if (validations.includes(false)) {
                result = "Proszę poprawić błędy w formularzu";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "pandemic_impact_finances_description",
                value: result
            });

            return result;
        }
    }
};
