//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateSurveyData } from "@/components/mixins";
import PpStepperInput from "@/components/PpStepperInput.vue";
import SurveyCheckbox from "../SurveyCheckbox.vue";

export default {
    mixins: [updateSurveyData],

    components: {
        PpStepperInput,
        SurveyCheckbox
    },

    props: {
        singleColumn: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        validate() {
            let result = true;
            const copy = { ...this.survey.other_residents };

            const arr = [
                "no_of_daughters",
                "no_of_friends",
                "no_of_female_friends",
                "no_of_grandmas",
                "no_of_grandpas",
                "no_of_sons"
            ];

            for (const exp of arr) {
                delete copy[exp];
            }

            if (!Object.values(copy).includes(true))
                result = "Proszę wybrać co najmniej jednego współlokatora";

            const validations = [];

            if (this.survey.other_residents.daughter) {
                validations.push(this.$refs.no_of_daughters.validate());
            }
            if (this.survey.other_residents.female_friend) {
                validations.push(this.$refs.no_of_female_friends.validate());
            }
            if (this.survey.other_residents.grandma) {
                validations.push(this.$refs.no_of_grandmas.validate());
            }
            if (this.survey.other_residents.grandpa) {
                validations.push(this.$refs.no_of_grandpas.validate());
            }
            if (this.survey.other_residents.son) {
                validations.push(this.$refs.no_of_sons.validate());
            }

            if (validations.includes(false)) {
                result = "Proszę poprawić błędy w formularzu";
            }

            this.$store.commit("formData/setSurveyValidation", {
                key: "other_residents",
                value: result
            });

            return result;
        }
    }
};
