var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"udfe"},[_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_living_alone",attrs:{"label":"Czy mieszkasz z kimś?","title":"Mieszkasz z kimś?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_living_alone.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.living_alone ? "Nie" : "Tak")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyLivingAlone',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.living_alone === false)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_other_residents",attrs:{"label":"Z kim mieszkasz?","title":"Z kim mieszkasz?","err":_vm.surveyValidations.other_residents !== true,"errMsg":_vm.displayErrorMessage('other_residents')},on:{"submit":function () {
                    var validation = _vm.$refs.other_residents.validate();
                    if (validation === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_other_residents.closeModal();
                    } else {
                        _vm.$message({
                            type: 'error',
                            msg: validation
                        });
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.displayOtherResidents())+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyOtherResidents',{ref:"other_residents",attrs:{"singleColumn":"","validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,1252015805)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_childs_has_childs",attrs:{"label":"Czy posiadasz dzieci?","title":"Czy posiadasz dzieci?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_childs_has_childs.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.childs.has_childs ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyChildsHasChilds',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.childs.has_childs === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_childs_count",attrs:{"label":"Ile dzieci posiadasz?","title":"Ile dzieci posiadasz?","err":_vm.surveyValidations.childs_count !== true,"errMsg":_vm.displayErrorMessage('childs_count')},on:{"submit":function () {
                    if (_vm.$refs.childs_count.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_childs_count.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.displayChildsCount())+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyChildsCount',{ref:"childs_count",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,1213627324)})],1):_vm._e(),(
            _vm.value.childs.has_childs === true &&
            (_vm.value.childs.sons.length > 0 || _vm.value.childs.daughters.length > 0)
        )?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_childs_dependencies",attrs:{"label":"Szczegóły","title":"Edytuj szczegóły","err":_vm.surveyValidations.childs_dependencies !== true,"errMsg":_vm.displayErrorMessage('childs_dependencies')},on:{"submit":function () {
                    if (_vm.$refs.childs_dependencies.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_childs_dependencies.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.displayChildsDependencies())+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyChildsDependencies',{ref:"childs_dependencies",attrs:{"single-column":"","validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,2713373149)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_is_married",attrs:{"label":"Czy jesteś w związku małżeńskim?","title":"Czy jesteś w związku małżeńskim?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_is_married.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.is_married ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyIsMarried',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.is_married === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_property_separation_status",attrs:{"label":"Czy panuje między Wami ustrój rozdzielności majątkowej?","title":"Posiadacie rozdzielność majątkową?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_property_separation_status.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.property_separation_status ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyPropertySeparationStatus',{ref:"property_separation_status",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,3160007849)})],1):_vm._e(),(_vm.value.property_separation_status === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_property_separation_start_date",attrs:{"label":"Od kiedy posiadacie rozdzielność majątkową?","title":"Data ustalenia rozdzielności majątk.","err":_vm.surveyValidations.property_separation_start_date !== true,"errMsg":_vm.displayErrorMessage('property_separation_start_date')},on:{"submit":function () {
                    if (_vm.$refs.property_separation_start_date.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_property_separation_start_date.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.surveyValidations.property_separation_start_date !== true ? "" : _vm.revData(_vm.survey.property_separation_start_date))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyPropertySeparationStartDate',{ref:"property_separation_start_date",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (_vm.$refs.property_separation_start_date.validate() === true) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_property_separation_start_date.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,83823761)})],1):_vm._e(),(_vm.value.is_married === false)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_is_divorced",attrs:{"label":"Czy jesteś po rozwodzie?","title":"Czy jesteś po rozwodzie?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_is_divorced.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.is_divorced ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyIsDivorced',{ref:"is_divorced",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,3391164748)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_own_company_has_own_company",attrs:{"label":"Czy prowadzisz działalność gospodarczą?","title":"Czy prowadzisz działalność gospodarczą?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_own_company_has_own_company.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.own_company.has_own_company ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyOwnCompanyHasOwnCompany',{ref:"own_company_has_own_company",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.own_company.has_own_company === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_own_company_is_suspended",attrs:{"label":"Czy jest zawieszona?","title":"Czy jest zawieszona?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_own_company_is_suspended.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.own_company.is_suspended ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyOwnCompanyIsSuspended',{ref:"own_company_is_suspended",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,101604355)})],1):_vm._e(),(
            _vm.value.own_company.is_suspended === false &&
            _vm.value.own_company.has_own_company === true
        )?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_own_company_start_date",attrs:{"label":"Od kiedy prowadzisz?","title":"Od kiedy prowadzisz?","err":_vm.surveyValidations.own_company_start_date !== true,"errMsg":_vm.displayErrorMessage('own_company_start_date')},on:{"submit":function () {
                    if (_vm.$refs.own_company_start_date.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_own_company_start_date.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.own_company.start_date && ("od " + (_vm.revData(_vm.survey.own_company.start_date))))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyOwnCompanyStartDate',{ref:"own_company_start_date",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (_vm.$refs.own_company_start_date.validate() === true) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_own_company_start_date.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,2820737394)})],1):_vm._e(),(_vm.value.own_company.is_suspended === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_own_company_suspension_date",attrs:{"label":"Od kiedy prowadziłeś i kiedy zawiesiłeś?","title":"Od kiedy prowadziłeś i kiedy zawiesiłeś?","err":_vm.surveyValidations.own_company_suspension_date !== true,"errMsg":_vm.displayErrorMessage('own_company_suspension_date')},on:{"submit":function () {
                    if (_vm.$refs.own_company_suspension_date.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_own_company_suspension_date.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(("" + (_vm.survey.own_company.start_date && _vm.survey.own_company.suspension_date && ("od " + (_vm.revData(_vm.survey.own_company.start_date)) + " do " + (_vm.revData(_vm.survey.own_company.suspension_date))))))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyOwnCompanySuspensionDate',{ref:"own_company_suspension_date",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (_vm.$refs.own_company_suspension_date.validate() === true) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_own_company_suspension_date.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,884900393)})],1):_vm._e(),(_vm.value.own_company.has_own_company === false)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_own_company_has_ever_running",attrs:{"label":"Czy kiedykolwiek prowadziłeś jdg?","title":"Czy kiedykolwiek prowadziłeś jdg?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_own_company_has_ever_running.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.own_company.has_ever_running ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyOwnCompanyHasEverRunning',{ref:"own_company_has_ever_running",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,2825477675)})],1):_vm._e(),(
            _vm.value.own_company.has_ever_running === true &&
            _vm.value.own_company.has_own_company === false
        )?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_own_company_close_date",attrs:{"label":"Od kiedy prowadziłeś i kiedy zamknąłeś?","title":"Od kiedy prowadziłeś i kiedy zamknąłeś?","err":_vm.surveyValidations.own_company_close_date !== true,"errMsg":_vm.displayErrorMessage('own_company_close_date')},on:{"submit":function () {
                    if (_vm.$refs.own_company_close_date.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_own_company_close_date.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.own_company.start_date && _vm.survey.own_company.close_date && ("od " + (_vm.revData(_vm.survey.own_company.start_date)) + " do " + (_vm.revData(_vm.survey.own_company.close_date))))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyOwnCompanyCloseDate',{ref:"own_company_close_date",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (_vm.$refs.own_company_close_date.validate() === true) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_own_company_close_date.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,47332203)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_hired_is_hired",attrs:{"label":"Czy jesteś gdzieś zatrudniony?","title":"Czy jesteś gdzieś zatrudniony?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_hired_is_hired.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.hired.is_hired ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyHiredIsHired',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.hired.is_hired === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_employment_place",attrs:{"label":"Miejsce zatrudnienia","title":"Miejsce zatrudnienia","err":_vm.surveyValidations.hired_employment_place !== true,"errMsg":_vm.displayErrorMessage('hired_employment_place')},on:{"submit":function () {
                    if (_vm.$refs.employment_place.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_employment_place.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(("" + (_vm.survey.hired.company_name && ((_vm.survey.hired.company_name) + " (od " + (_vm.revData( _vm.survey.hired.start_date )) + ")"))))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyEmploymentPlace',{ref:"employment_place",attrs:{"validate-on-mount":"","employment-type":"hired"},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,239423263)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_odd_job",attrs:{"label":"Czy pracujesz dorywczo?","title":"Czy pracujesz dorywczo?","err":_vm.surveyValidations.odd_job !== true,"errMsg":_vm.displayErrorMessage('odd_job')},on:{"submit":function () {
                    if (this$1.$refs.odd_job.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_odd_job.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.odd_job.is_hired ? ("Tak (dochód: " + (_vm.survey.odd_job.income || "b/d") + ")") : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyOddJob',{ref:"odd_job",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (_vm.$refs.odd_job.validate() === true) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_odd_job.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_l6m_income_has_income",attrs:{"label":"Czy osiągałeś(aś) przychody w ostatnich 6 miesiącach?","title":"Czy osiągałeś(aś) przychody w ostatnich 6 miesiącach?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_l6m_income_has_income.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.l6m_income.has_income ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyLast6MonthsIncomeHasIncome',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.l6m_income.has_income)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_l6m_income_items",attrs:{"label":"Przychody z ostatnich 6 miesięcy","title":"Przychody z ostatnich 6 miesięcy","err":_vm.surveyValidations.l6m_income_items !== true,"errMsg":_vm.displayErrorMessage('l6m_income_items')},on:{"submit":function () {
                    var validation = _vm.$refs.l6m_income_items.validate();
                    if (validation === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_l6m_income_items.closeModal();
                    } else {
                        _vm.$message({
                            type: 'error',
                            msg: validation
                        });
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(("Dodanych przychodów: " + (_vm.survey.l6m_income.items.length)))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyAddIncomeSources',{ref:"l6m_income_items",attrs:{"mobile":"","validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,268291069)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_unemployed_status_has_status",attrs:{"label":"Czy posiadasz status osoby bezrobotnej?","title":"Czy posiadasz status osoby bezrobotnej?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_unemployed_status_has_status.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.unemployed_status.has_status ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyUnemployedStatusHasStatus',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.unemployed_status.has_status)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_unemployed_status_status_grant_date",attrs:{"label":"Od kiedy jesteś bezrobotny?","title":"Od kiedy jesteś bezrobotny?","err":_vm.surveyValidations.unemployed_status_grant_date !== true,"errMsg":_vm.displayErrorMessage('unemployed_status_grant_date')},on:{"submit":function () {
                    if (_vm.$refs.unemployed_status_status_grant_date.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_unemployed_status_status_grant_date.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.unemployed_status.status_grant_date && ("od " + (_vm.revData(_vm.survey.unemployed_status.status_grant_date))))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyUnemployedStatusStatusGrantDate',{ref:"unemployed_status_status_grant_date",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (
                                    _vm.$refs.unemployed_status_status_grant_date.validate() ===
                                    true
                                ) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_unemployed_status_status_grant_date.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,1660085479)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_pandemic_impact_affected_employment",attrs:{"label":"Czy pandemia wpłynęła na Twoje zatrudnienie?","title":"Czy pandemia wpłynęła na Twoje zatrudnienie?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_pandemic_impact_affected_employment.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.pandemic_impact.affected_employment ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyPandemicImpactAffectedEmployment',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.pandemic_impact.affected_employment === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_pandemic_impact_employment_affection_description",attrs:{"label":"Opis","title":"Opis","err":_vm.surveyValidations.pandemic_impact_employment_description !== true,"errMsg":_vm.displayErrorMessage('pandemic_impact_employment_description')},on:{"submit":function () {
                    if (_vm.$refs.pandemic_impact_affected_employment.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_pandemic_impact_employment_affection_description.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.survey.pandemic_impact.employment_affection_description)+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyPandemicImpactEmploymentAffectionDescription',{ref:"pandemic_impact_affected_employment",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,3541789177)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_retirement_is_retired",attrs:{"label":"Czy jesteś na emeryturze?","title":"Czy jesteś na emeryturze?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_retirement_is_retired.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.retirement.is_retired ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyRetirementIsRetired',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.retirement.is_retired === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_retirement_details",attrs:{"label":"Szczegóły emerytury","title":"Szczegóły emerytury","err":_vm.surveyValidations.retirement_details !== true,"errMsg":_vm.displayErrorMessage('retirement_details')},on:{"submit":function () {
                    if (_vm.$refs.retirement_details.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_retirement_details.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.survey.retirement.amount && _vm.survey.retirement.retirement_date && ((_vm.survey.retirement.amount) + " (od " + (_vm.revData( _vm.survey.retirement.retirement_date )) + ")"))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyRetirementDetails',{ref:"retirement_details",attrs:{"validate-on-mount":"","single-column":""},on:{"submit":function () {
                                if (_vm.$refs.retirement_details.validate() === true) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_retirement_details.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,1259480515)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_health_pension_is_pensioner",attrs:{"label":"Czy jesteś na rencie?","title":"Czy jesteś na rencie?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_health_pension_is_pensioner.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.health_pension.is_pensioner ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyHealthPensionIsPensioner',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.health_pension.is_pensioner === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_health_pension_details",attrs:{"label":"Szczegóły renty","title":"Szczegóły renty","err":_vm.surveyValidations.health_pension_details !== true,"errMsg":_vm.displayErrorMessage('health_pension_details')},on:{"submit":function () {
                    if (_vm.$refs.health_pension_details.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_health_pension_details.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.survey.health_pension.amount && _vm.survey.health_pension.obtain_date && ((_vm.survey.health_pension.amount) + " (od " + (_vm.revData( _vm.survey.health_pension.obtain_date )) + ") Powód: " + (_vm.survey.health_pension.reason)))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyHealthPensionDetails',{ref:"health_pension_details",attrs:{"single-column":"","validate-on-mount":""},on:{"submit":function () {
                                if (_vm.$refs.health_pension_details.validate() === true) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_health_pension_details.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,779382018)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_disabled_person_is_disabled",attrs:{"label":"Czy jesteś osobą niepełnosprawną?","title":"Czy jesteś osobą niepełnosprawną?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_disabled_person_is_disabled.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.disabled_person.is_disabled ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyDisabledPersonIsDisabled',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.disabled_person.is_disabled === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_disabled_person_details",attrs:{"label":"Szczegóły niepełnosprawności","title":"Szczegóły niepełnosprawności","err":_vm.surveyValidations.disabled_person_details !== true,"errMsg":_vm.displayErrorMessage('disabled_person_details')},on:{"submit":function () {
                    if (_vm.$refs.disabled_person_details.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_disabled_person_details.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.survey.disabled_person.disability_status_date && _vm.displayDisabledPersonDetails())+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyDisabledPersonDetails',{ref:"disabled_person_details",attrs:{"validate-on-mount":"","single-column":""},on:{"submit":function () {
                                if (_vm.$refs.disabled_person_details.validate() === true) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_disabled_person_details.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,250432354)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_pandemic_impact_affected_finances",attrs:{"label":"Czy pandemia wpłynęła na Twoją sytuację finansową?","title":"Czy pandemia wpłynęła na Twoją sytuację finansową?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_pandemic_impact_affected_finances.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.pandemic_impact.affected_finances ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyPandemicImpactAffectedFinances',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.pandemic_impact.affected_finances === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_pandemic_impact_finances_affection_details",attrs:{"label":"Opis","title":"Opis","err":_vm.surveyValidations.pandemic_impact_finances_description !== true,"errMsg":_vm.displayErrorMessage('pandemic_impact_finances_description')},on:{"submit":function () {
                    if (_vm.$refs.pandemic_impact_finances_affection_details.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_pandemic_impact_finances_affection_details.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.displayFinancesAffectionDetails())+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyPandemicImpactFinancesAffectionDetails',{ref:"pandemic_impact_finances_affection_details",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,586034119)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_l12m_movable_property_transactions_transactions_made",attrs:{"label":"Czy w ostatnich 12 miesiącach dokonywałeś(aś) czynności prawnych?","title":"Czy w ostatnich 12 miesiącach dokonywałeś(aś) czynności prawnych?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_l12m_movable_property_transactions_transactions_made.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.l12m_movable_property_transactions.transactions_made ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyLast12MonthsMovablePropertyTransactionsTransactionsMade',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.l12m_movable_property_transactions.transactions_made)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_l12m_movable_property_transactions_transactions_on_movables",attrs:{"label":"Transakcje","title":"Transakcje","err":_vm.surveyValidations.l12m_movable_property_transactions !== true,"errMsg":_vm.displayErrorMessage('l12m_movable_property_transactions')},on:{"submit":function () {
                    var validation =
                        _vm.$refs.l12m_movable_property_transactions_transactions_on_movables.validate();
                    if (validation === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_l12m_movable_property_transactions_transactions_on_movables.closeModal();
                    } else {
                        _vm.$message({
                            type: 'error',
                            msg: validation
                        });
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(("Dodanych transakcji: " + (_vm.survey.l12m_movable_property_transactions.transactions.length)))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyAddTransactionsOnMovables',{ref:"l12m_movable_property_transactions_transactions_on_movables",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,1025305252)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_incurring_debts_moment_creditworthiness",attrs:{"label":"Czy w chwili zaciągania długów miałeś(aś) zdolność kredytową?","title":"Czy w chwili zaciągania długów miałeś(aś) zdolność kredytową?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_incurring_debts_moment_creditworthiness.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.displayIncurringDebtsMomentCreditwrothiness())+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyIncurringDebtsMomentCreditworthiness',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_incurring_debts_moment_job",attrs:{"label":"Czy w chwili zaciągania długów miałeś(aś) zatrudnienie?","title":"Czy w chwili zaciągania długów miałeś(aś) zatrudnienie?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_incurring_debts_moment_job.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.incurring_debts_moment.job ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyIncurringDebtsMomentJob',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_incurring_debts_moment_marriage",attrs:{"label":"Czy w chwili zaciągania długów byłeś(aś) w związku małżeńskim?","title":"Czy w chwili zaciągania długów byłeś(aś) w związku małżeńskim?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_incurring_debts_moment_marriage.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.incurring_debts_moment.marriage ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyIncurringDebtsMomentMarriage',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.incurring_debts_moment.marriage === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_incurring_debts_moment_spouse_job",attrs:{"label":"Czy w chwili zaciągania długów małżonek/małżonka miał(a) zatrudnienie?","title":"Czy w chwili zaciągania długów małżonek/małżonka miał(a) zatrudnienie?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_incurring_debts_moment_spouse_job.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.incurring_debts_moment.spouse_job ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyIncurringDebtsMomentSpouseJob',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,3969599140)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_borrowed_funds_allocation_previous_loan_repayment",attrs:{"label":"Czy środki z poszczególnych umów pożyczek lub kredytów zostały przeznaczone na spłatę poprzednich pożyczek lub kredytów?","title":"Czy środki z poszczególnych umów pożyczek lub kredytów zostały przeznaczone na spłatę poprzednich pożyczek lub kredytów?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_borrowed_funds_allocation_previous_loan_repayment.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.displayBorrowedFundsAllocationPreviousLoanRepayment())+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyBorrowedFundsAllocationPreviousLoanRepayment',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.borrowed_funds_allocation.previous_loan_repayment !== 'no')?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_borrowed_funds_allocation_previous_loan_repayment_amount",attrs:{"label":"Jaka kwota została przeznaczona?","title":"Jaka kwota została przeznaczona?","err":_vm.surveyValidations.borrowed_funds_allocation_previous_loan_repayment_amount !==
                true,"errMsg":_vm.displayErrorMessage('borrowed_funds_allocation_previous_loan_repayment_amount')},on:{"submit":function () {
                    if (
                        _vm.$refs.borrowed_funds_allocation_previous_loan_repayment_amount.validate() ===
                        true
                    ) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_borrowed_funds_allocation_previous_loan_repayment_amount.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.survey.borrowed_funds_allocation.previous_loan_repayment_amount)+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyBorrowedFundsAllocationPreviousLoanRepaymentAmount',{ref:"borrowed_funds_allocation_previous_loan_repayment_amount",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (
                                    _vm.$refs.borrowed_funds_allocation_previous_loan_repayment_amount.validate() ===
                                    true
                                ) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_borrowed_funds_allocation_previous_loan_repayment_amount.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,270230504)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_borrowed_funds_allocation_bills_and_living",attrs:{"label":"Czy środki z poszczególnych umów pożyczek lub kredytów zostały przeznaczone na bieżące rachunki oraz na życie?","title":"Czy środki z poszczególnych umów pożyczek lub kredytów zostały przeznaczone na bieżące rachunki oraz na życie?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_borrowed_funds_allocation_bills_and_living.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.displayBorrowedFundsAllocationBillsAndLiving())+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyBorrowedFundsAllocationBillsAndLiving',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.borrowed_funds_allocation.bills_and_living !== 'no')?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_borrowed_funds_allocation_bills_and_living_amount",attrs:{"label":"Jaka kwota została przeznaczona na bieżące rachunki i życie?","title":"Jaka kwota została przeznaczona na bieżące rachunki i życie?","err":_vm.surveyValidations.borrowed_funds_allocation_bills_and_living_amount !== true,"errMsg":_vm.displayErrorMessage('borrowed_funds_allocation_bills_and_living_amount')},on:{"submit":function () {
                    if (
                        _vm.$refs.borrowed_funds_allocation_bills_and_living_amount.validate() ===
                        true
                    ) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_borrowed_funds_allocation_bills_and_living_amount.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.survey.borrowed_funds_allocation.bills_and_living_amount)+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyBorrowedFundsAllocationBillsAndLivingAmount',{ref:"borrowed_funds_allocation_bills_and_living_amount",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (
                                    _vm.$refs.borrowed_funds_allocation_bills_and_living_amount.validate() ===
                                    true
                                ) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_borrowed_funds_allocation_bills_and_living_amount.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,2874560056)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_borrowed_funds_allocation_treatment",attrs:{"label":"Czy środki z poszczególnych umów pożyczek lub kredytów zostały przeznaczone na leczenie?","title":"Czy środki z poszczególnych umów pożyczek lub kredytów zostały przeznaczone na leczenie?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_borrowed_funds_allocation_treatment.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.displayBorrowedFundsAllocationTreatment())+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyBorrowedFundsAllocationTreatment',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.borrowed_funds_allocation.treatment !== 'no')?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_borrowed_funds_allocation_treatment_details",attrs:{"label":"Szczegóły leczenia","title":"Szczegóły leczenia","err":_vm.surveyValidations.borrowed_funds_allocation_treatment_details !== true,"errMsg":_vm.displayErrorMessage('borrowed_funds_allocation_treatment_details')},on:{"submit":function () {
                    if (_vm.$refs.borrowed_funds_allocation_treatment_details.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_borrowed_funds_allocation_treatment_details.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.survey.borrowed_funds_allocation.treatment_monthly_amount && _vm.survey.borrowed_funds_allocation.treated_diseases && ((_vm.survey.borrowed_funds_allocation.treatment_monthly_amount) + " Choroby: " + (_vm.survey.borrowed_funds_allocation.treated_diseases)))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyBorrowedFundsAllocationTreatmentDetails',{ref:"borrowed_funds_allocation_treatment_details",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (
                                    _vm.$refs.borrowed_funds_allocation_treatment_details.validate() ===
                                    true
                                ) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_borrowed_funds_allocation_treatment_details.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,767783907)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_shareholding_debt_responsibility",attrs:{"label":"Czy byłeś(aś) akcjonariuszem lub komandytariuszem odpowiadającym również za długi spółki?","title":"Czy byłeś(aś) akcjonariuszem lub komandytariuszem odpowiadającym również za długi spółki?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_shareholding_debt_responsibility.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.shareholding.debt_responsibility ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyShareholdingDebtResponsibility',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.shareholding.debt_responsibility === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_shareholding_debt_responsibility_debt_responsibility_end_date",attrs:{"label":"Do kiedy?","title":"Do kiedy?","err":_vm.surveyValidations.shareholding_debt_responsibility_end_date !== true,"errMsg":_vm.displayErrorMessage('shareholding_debt_responsibility_end_date')},on:{"submit":function () {
                    if (
                        _vm.$refs.shareholding_debt_responsibility_debt_responsibility_end_date.validate() ===
                        true
                    ) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_shareholding_debt_responsibility_debt_responsibility_end_date.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.shareholding.debt_responsibility_end_date && ("do " + (_vm.revData(_vm.survey.shareholding.debt_responsibility_end_date))))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyShareholdingDebtResponsibilityEndDate',{ref:"shareholding_debt_responsibility_debt_responsibility_end_date",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (
                                    _vm.$refs.shareholding_debt_responsibility_debt_responsibility_end_date.validate() ===
                                    true
                                ) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_shareholding_debt_responsibility_debt_responsibility_end_date.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,3985550108)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_l10y_bankruptcy_application_application_submitted",attrs:{"label":"Czy w ciągu ostatnich 10 lat składałeś(aś) już wniosek o upadłość?","title":"Czy w ciągu ostatnich 10 lat składałeś(aś) już wniosek o upadłość?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_l10y_bankruptcy_application_application_submitted.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.l10y_bankruptcy_application.application_submitted ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyLast10YearsBankruptcyApplicationApplicationSubmitted',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.l10y_bankruptcy_application.application_submitted === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_l10y_bankruptcy_application_court_decision_date",attrs:{"label":"Kiedy była decyzja sądu?","title":"Kiedy była decyzja sądu?","err":_vm.surveyValidations.l10y_bankruptcy_application_court_decision_date !== true,"errMsg":_vm.displayErrorMessage('l10y_bankruptcy_application_court_decision_date')},on:{"submit":function () {
                    if (
                        _vm.$refs.l10y_bankruptcy_application_court_decision_date.validate() ===
                        true
                    ) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_l10y_bankruptcy_application_court_decision_date.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(("" + (_vm.revData(_vm.survey.l10y_bankruptcy_application.court_decision_date))))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyLast10YearsBankruptcyApplicationCourtDecisionDate',{ref:"l10y_bankruptcy_application_court_decision_date",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (
                                    _vm.$refs.l10y_bankruptcy_application_court_decision_date.validate() ===
                                    true
                                ) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_l10y_bankruptcy_application_court_decision_date.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,2865792260)})],1):_vm._e(),(_vm.value.l10y_bankruptcy_application.application_submitted === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_l10y_bankruptcy_application_debt_cancellation",attrs:{"label":"Czy część lub całość zobowiązań została wtedy umorzona?","title":"Czy część lub całość zobowiązań została wtedy umorzona?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_l10y_bankruptcy_application_debt_cancellation.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.l10y_bankruptcy_application.debt_cancellation ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyLast10YearsBankruptcyApplicationDebtCancellation',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,2775086695)})],1):_vm._e(),(
            _vm.value.l10y_bankruptcy_application.debt_cancellation === true &&
            _vm.value.l10y_bankruptcy_application.application_submitted === true
        )?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_l10y_bankruptcy_application_debt_cancellation_amount",attrs:{"label":"Jaka część % została umorzona?","title":"Jaka część % została umorzona?","err":_vm.surveyValidations.l10y_bankruptcy_application_debt_cancellation_amount !== true,"errMsg":_vm.displayErrorMessage('l10y_bankruptcy_application_debt_cancellation_amount')},on:{"submit":function () {
                    if (
                        _vm.$refs.l10y_bankruptcy_application_debt_cancellation_amount.validate() ===
                        true
                    ) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_l10y_bankruptcy_application_debt_cancellation_amount.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.survey.l10y_bankruptcy_application.debt_cancellation_amount)+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyLast10YearsBankruptcyApplicationDebtCancellationAmount',{ref:"l10y_bankruptcy_application_debt_cancellation_amount",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (
                                    _vm.$refs.l10y_bankruptcy_application_debt_cancellation_amount.validate() ===
                                    true
                                ) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_l10y_bankruptcy_application_debt_cancellation_amount.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,2397892388)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_l10y_creditors_detriment",attrs:{"label":"Czy w ciągu 10 lat dokonałeś(aś) czynności skutkującej pokrzywdzeniem wierzycieli?","title":"Czy w ciągu 10 lat dokonałeś(aś) czynności skutkującej pokrzywdzeniem wierzycieli?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_l10y_creditors_detriment.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.l10y_creditors_detriment ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyLast10YearsCreditorsDetriment',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.l10y_creditors_detriment === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_l10y_creditors_detriment_description",attrs:{"label":"Opisz krótko co przekazałeś, komu? Jaka była wartość przekazania?","title":"Opisz krótko co przekazałeś, komu? Jaka była wartość przekazania?","err":_vm.surveyValidations.l10y_creditors_detriment_description !== true,"errMsg":_vm.displayErrorMessage('l10y_creditors_detriment_description')},on:{"submit":function () {
                    if (_vm.$refs.l10y_creditors_detriment_description.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_l10y_creditors_detriment_description.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.survey.l10y_creditors_detriment_description)+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyLast10YearsCreditorsDetrimentDescription',{ref:"l10y_creditors_detriment_description",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,3860319104)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_real_estate_ownership",attrs:{"label":"Czy jesteś właścicielem jakiegoś lokalu mieszkalnego?","title":"Czy jesteś właścicielem jakiegoś lokalu mieszkalnego?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_real_estate_ownership.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.displayRealEstateOwnership())+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyRealEstateOwnership',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.real_estate.ownership === 'shared_ownership')?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_real_estate_co_owners",attrs:{"label":"Kto jest współwłaścicielem?","title":"Kto jest współwłaścicielem?","err":_vm.surveyValidations.real_estate_co_owners !== true,"errMsg":_vm.displayErrorMessage('real_estate_co_owners')},on:{"submit":function () {
                    if (_vm.$refs.real_estate_co_owners.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_real_estate_co_owners.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.survey.real_estate.co_owners)+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyRealEstateCoOwners',{ref:"real_estate_co_owners",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (_vm.$refs.real_estate_co_owners.validate() === true) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_real_estate_co_owners.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,180429798)})],1):_vm._e(),(_vm.value.real_estate.ownership !== 'no')?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_real_estate_details",attrs:{"label":"Szczegóły własności","title":"Szczegóły własności","err":_vm.surveyValidations.real_estate_details !== true,"errMsg":_vm.displayErrorMessage('real_estate_details')},on:{"submit":function () {
                    if (_vm.$refs.real_estate_details.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_real_estate_details.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(((_vm.survey.real_estate.ownership_percentage && (" Wielkość własności: " + (_vm.survey.real_estate.ownership_percentage) + "% ")) + " " + (_vm.survey.real_estate.property_value && (" Wartość: " + (_vm.survey.real_estate.property_value))) + " "))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyRealEstateDetails',{ref:"real_estate_details",attrs:{"validate-on-mount":"","single-column":""},on:{"submit":function () {
                                if (_vm.$refs.real_estate_details.validate() === true) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_real_estate_details.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,1521854488)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_flat_rent",attrs:{"label":"Czy wynajmujesz jakiś lokal mieszkalny?","title":"Czy wynajmujesz jakiś lokal mieszkalny?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_flat_rent.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.flat_rent.is_renting ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyFlatRentIsRenting',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.flat_rent.is_renting === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_flat_rent_details",attrs:{"label":"Szczegóły wynajmu","title":"Szczegóły wynajmu","err":_vm.surveyValidations.flat_rent_details !== true,"errMsg":_vm.displayErrorMessage('flat_rent_details')},on:{"submit":function () {
                    if (_vm.$refs.flat_rent_details.validate() === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_flat_rent_details.closeModal();
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text mr-10"},[_vm._v(" "+_vm._s(_vm.survey.flat_rent.flat_size && _vm.survey.flat_rent.rent_price && ("Metraż: " + (_vm.survey.flat_rent.flat_size) + " Cena wynajmu: " + (_vm.survey.flat_rent.rent_price)))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyFlatRentDetails',{ref:"flat_rent_details",attrs:{"validate-on-mount":""},on:{"submit":function () {
                                if (_vm.$refs.flat_rent_details.validate() === true) {
                                    _vm.emitUpdate();
                                    _vm.$refs.edit_flat_rent_details.closeModal();
                                }
                            }},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,3582540280)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_has_bank_accounts",attrs:{"label":"Czy posiadasz rachunek/rachunki bankowe?","title":"Czy posiadasz rachunek/rachunki bankowe?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_has_bank_accounts.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.has_bank_accounts ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyHasBankAccounts',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.has_bank_accounts === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_bank_accounts",attrs:{"label":"Konta bankowe","title":"Konta bankowe","err":_vm.surveyValidations.bank_accounts !== true,"errMsg":_vm.displayErrorMessage('bank_accounts')},on:{"submit":function () {
                    var validation = _vm.$refs.bank_accounts.validate();
                    if (validation === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_bank_accounts.closeModal();
                    } else {
                        _vm.$message({
                            type: 'error',
                            msg: validation
                        });
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(("Dodanych kont bankowych: " + (_vm.survey.bank_accounts.length)))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyAddBankAccounts',{ref:"bank_accounts",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,3568089492)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_has_bank_deposits",attrs:{"label":"Czy posiadasz lokatę/lokaty bankowe?","title":"Czy posiadasz lokatę/lokaty bankowe?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_has_bank_deposits.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.has_bank_deposits ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyHasBankDeposits',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.has_bank_deposits === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_bank_deposits",attrs:{"label":"Lokaty bankowe","title":"Lokaty bankowe","err":_vm.surveyValidations.bank_deposits !== true,"errMsg":_vm.displayErrorMessage('bank_deposits')},on:{"submit":function () {
                    var validation = _vm.$refs.bank_deposits.validate();
                    if (validation === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_bank_deposits.closeModal();
                    } else {
                        _vm.$message({
                            type: 'error',
                            msg: validation
                        });
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(("Dodanych lokat bankowych: " + (_vm.survey.bank_deposits.length)))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyAddBankDeposits',{ref:"bank_deposits",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,2852161510)})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_has_cash",attrs:{"label":"Czy posiadasz gotówkę?","title":"Czy posiadasz gotówkę?"},on:{"submit":function () {
                    _vm.emitUpdate();
                    _vm.$refs.edit_has_cash.closeModal();
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.survey.has_cash ? "Tak" : "Nie")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyHasCash',{attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}])})],1),(_vm.value.has_cash === true)?_c('div',{staticClass:"mb-4"},[_c('PpEditorData',{ref:"edit_cash",attrs:{"label":"Gotówka","title":"Gotówka","err":_vm.surveyValidations.cash !== true,"errMsg":_vm.displayErrorMessage('cash')},on:{"submit":function () {
                    var validation = _vm.$refs.cash.validate();
                    if (validation === true) {
                        _vm.emitUpdate();
                        _vm.$refs.edit_cash.closeModal();
                    } else {
                        _vm.$message({
                            type: 'error',
                            msg: validation
                        });
                    }
                },"close":function () {
                    _vm.handleValue();
                }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(("Dodanych źródeł gotówki: " + (_vm.survey.cash.length)))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('SurveyAddCash',{ref:"cash",attrs:{"validate-on-mount":""},model:{value:(_vm.survey),callback:function ($$v) {_vm.survey=$$v},expression:"survey"}})],1)]},proxy:true}],null,false,3640680489)})],1):_vm._e(),_c('PpDialog',{attrs:{"show":_vm.isDisplayConfirmModal,"cancelable":false,"max-width":"450"},on:{"close":_vm.closeConfirmDataModal},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s("Zatwierdzenie danych"))]},proxy:true}])},[_vm._v(" Upewnij się, że wprowadzone dane są poprawne. "),_c('b',[_vm._v("Uwaga!")]),_vm._v(" Po zatwierdzeniu utracisz możliwość ich edycji. "),_c('PpDialogFooter',{attrs:{"slot":"footer"},slot:"footer"},[_c('PpButton',{attrs:{"text":""},on:{"click":_vm.closeConfirmDataModal}},[_vm._v("Anuluj")]),_c('PpButton',{attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.sendSurveyData}},[_vm._v("Wyślij")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }