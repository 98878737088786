var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"income_source",attrs:{"type":"text","label":"Źródło przychodu","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 63) { return 'Maksymalna długość źródła przychodu to 63 znaki'; }
                    return true;
                }
            ]},model:{value:(_vm.local_source.income_source),callback:function ($$v) {_vm.$set(_vm.local_source, "income_source", $$v)},expression:"local_source.income_source"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"avg_income",attrs:{"type":"text","label":"Średni przychód","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 31)
                        { return 'Maksymalna długość średniego przychodu to 31 znaków'; }
                    if (!this$1.$store.state.REGEX_IS_MONEY.test(v))
                        { return 'Proszę podać prawidłową kwotę'; }
                    return true;
                }
            ]},model:{value:(_vm.local_source.avg_income),callback:function ($$v) {_vm.$set(_vm.local_source, "avg_income", $$v)},expression:"local_source.avg_income"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkTextarea',{ref:"additional_info",attrs:{"label":"Dodatkowe informacje","type":"textarea","counter":"511","autogrow":"","rows":8,"rules":[
                function (v) {
                    if (v.length > 511) {
                        return 'Maksymalna długość opsiu dodatkowych informacji to 511 znaków';
                    }
                    return true;
                }
            ]},model:{value:(_vm.local_source.additional_info),callback:function ($$v) {_vm.$set(_vm.local_source, "additional_info", $$v)},expression:"local_source.additional_info"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkCheckbox',{attrs:{"type":"checkbox","label":"Zajęcie komornicze"},model:{value:(_vm.local_source.bailiff_seizure),callback:function ($$v) {_vm.$set(_vm.local_source, "bailiff_seizure", $$v)},expression:"local_source.bailiff_seizure"}})],1),_c('div',{staticClass:"mb-4"},[(_vm.local_source.bailiff_seizure)?_c('WkTextField',{ref:"no_of_bailiffs",attrs:{"type":"number","label":"Liczba komorników zajmujących przychód","rules":[
                function (v) {
                    if (v < 1) { return 'Minimalna liczba komorników to 1'; }
                    if (v > 127) { return 'Maksymalna liczba komorników to 127'; }
                    return true;
                }
            ]},model:{value:(_vm.local_source.no_of_bailiffs),callback:function ($$v) {_vm.$set(_vm.local_source, "no_of_bailiffs", _vm._n($$v))},expression:"local_source.no_of_bailiffs"}}):_vm._e()],1),_c('div',{staticClass:"mb-4"},[(_vm.local_source.bailiff_seizure)?_c('WkTextField',{ref:"seizure_size",attrs:{"type":"text","label":"Wysokość kwotowa zajęcia","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 31)
                        { return 'Maksymalna długość kwoty zajęcia komorniczego to 31 znaków'; }
                    if (!this$1.$store.state.REGEX_IS_MONEY.test(v))
                        { return 'Proszę podać prawidłową kwotę'; }
                    return true;
                }
            ]},model:{value:(_vm.local_source.seizure_size),callback:function ($$v) {_vm.$set(_vm.local_source, "seizure_size", $$v)},expression:"local_source.seizure_size"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }